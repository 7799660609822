export const languages = [
  { id: 1, label: "Afrikaans", value: "af" },
  { id: 2, label: "Albanian", value: "sq" },
  { id: 3, label: "Amharic", value: "am" },
  { id: 4, label: "Arabic", value: "ar" },
  { id: 5, label: "Armenian", value: "hy" },
  { id: 6, label: "Azerbaijani", value: "az" },
  { id: 7, label: "Bengali", value: "bn" },
  { id: 8, label: "Bosnian", value: "bs" },
  { id: 9, label: "Bulgarian", value: "bg" },
  { id: 10, label: "Catalan", value: "ca" },
  { id: 11, label: "Chinese (Simplified)", value: "zh" },
  { id: 12, label: "Chinese (Traditional)", value: "zh-TW" },
  { id: 13, label: "Croatian", value: "hr" },
  { id: 14, label: "Czech", value: "cs" },
  { id: 15, label: "Danish", value: "da" },
  { id: 16, label: "Dari", value: "fa-AF" },
  { id: 17, label: "Dutch", value: "nl" },
  { id: 18, label: "English", value: "en" },
  { id: 19, label: "Estonian", value: "et" },
  { id: 20, label: "Farsi (Persian)", value: "fa" },
  { id: 21, label: "Filipino, Tagalog", value: "tl" },
  { id: 22, label: "Finnish", value: "fi" },
  { id: 23, label: "French", value: "fr" },
  { id: 24, label: "French (Canada)", value: "fr-CA" },
  { id: 25, label: "Georgian", value: "ka" },
  { id: 26, label: "German", value: "de" },
  { id: 27, label: "Greek", value: "el" },
  { id: 28, label: "Gujarati", value: "gu" },
  { id: 29, label: "Haitian Creole", value: "ht" },
  { id: 30, label: "Hausa", value: "ha" },
  { id: 31, label: "Hebrew", value: "he" },
  { id: 32, label: "Hindi", value: "hi" },
  { id: 33, label: "Hungarian", value: "hu" },
  { id: 34, label: "Icelandic", value: "is" },
  { id: 35, label: "Indonesian", value: "id" },
  { id: 36, label: "Irish", value: "ga" },
  { id: 37, label: "Italian", value: "it" },
  { id: 38, label: "Japanese", value: "ja" },
  { id: 39, label: "Kannada", value: "kn" },
  { id: 40, label: "Kazakh", value: "kk" },
  { id: 41, label: "Korean", value: "ko" },
  { id: 42, label: "Latvian", value: "lv" },
  { id: 43, label: "Lithuanian", value: "lt" },
  { id: 44, label: "Macedonian", value: "mk" },
  { id: 45, label: "Malay", value: "ms" },
  { id: 46, label: "Malayalam", value: "ml" },
  { id: 47, label: "Maltese", value: "mt" },
  { id: 48, label: "Marathi", value: "mr" },
  { id: 49, label: "Mongolian", value: "mn" },
  { id: 50, label: "Norwegian (Bokmål)", value: "no" },
  { id: 51, label: "Pashto", value: "ps" },
  { id: 52, label: "Polish", value: "pl" },
  { id: 53, label: "Portuguese (Brazil)", value: "pt" },
  { id: 54, label: "Portuguese (Portugal)", value: "pt-PT" },
  { id: 55, label: "Punjabi", value: "pa" },
  { id: 56, label: "Romanian", value: "ro" },
  { id: 57, label: "Russian", value: "ru" },
  { id: 58, label: "Serbian", value: "sr" },
  { id: 59, label: "Sinhala", value: "si" },
  { id: 60, label: "Slovak", value: "sk" },
  { id: 61, label: "Slovenian", value: "sl" },
  { id: 62, label: "Somali", value: "so" },
  { id: 63, label: "Spanish", value: "es" },
  { id: 64, label: "Spanish (Mexico)", value: "es-MX" },
  { id: 65, label: "Swahili", value: "sw" },
  { id: 66, label: "Swedish", value: "sv" },
  { id: 67, label: "Tamil", value: "ta" },
  { id: 68, label: "Telugu", value: "te" },
  { id: 69, label: "Thai", value: "th" },
  { id: 70, label: "Turkish", value: "tr" },
  { id: 71, label: "Ukrainian", value: "uk" },
  { id: 72, label: "Urdu", value: "ur" },
  { id: 73, label: "Uzbek", value: "uz" },
  { id: 74, label: "Vietlabelse", value: "vi" },
  { id: 75, label: "Welsh", value: "cy" },
];

export const dummy_appointments = {
  type: "searchset",
  entry: [
    {
      resource: {
        end: "2024-02-01T10:30:00-05:00",
        type: {
          text: "Follow Up - Patient: Tele-Med",
        },
        participant: [
          {
            status: "accepted",
            actor: {
              display: "Ross Rectenwald",
              reference: "Patient/7",
            },
          },
          {
            status: "accepted",
            actor: {
              display: "Brian Aldred, MD",
              reference: "Practitioner/5",
            },
          },
        ],
        resourceType: "Appointment",
        start: "2024-02-01T10:00:00-05:00",
        status: "booked",
        minutesDuration: 30,
        id: "13979",
      },
      fullUrl:
        "https://api.preview.platform.athenahealth.com/v1/2636801/1/fhir/dstu2/Appointment/13979",
    },
    {
      resource: {
        end: "2024-02-01T10:00:00-05:00",
        type: {
          text: "Follow Up - Patient: Tele-Med",
        },
        participant: [
          {
            status: "accepted",
            actor: {
              display: "Ferdinando Mirarchi",
              reference: "Patient/5",
            },
          },
          {
            status: "accepted",
            actor: {
              display: "Brian Aldred, MD",
              reference: "Practitioner/5",
            },
          },
        ],
        resourceType: "Appointment",
        start: "2024-02-01T09:30:00-05:00",
        status: "booked",
        minutesDuration: 30,
        id: "13977",
      },
      fullUrl:
        "https://api.preview.platform.athenahealth.com/v1/2636801/1/fhir/dstu2/Appointment/13977",
    },
    {
      resource: {
        end: "2024-02-01T11:00:00-05:00",
        type: {
          text: "Follow Up - Patient: Tele-Med",
        },
        participant: [
          {
            status: "accepted",
            actor: {
              display: "Joy Rectenwald",
              reference: "Patient/8",
            },
          },
          {
            status: "accepted",
            actor: {
              display: "Brian Aldred, MD",
              reference: "Practitioner/5",
            },
          },
        ],
        resourceType: "Appointment",
        start: "2024-02-01T10:30:00-05:00",
        status: "booked",
        minutesDuration: 30,
        id: "13980",
      },
      fullUrl:
        "https://api.preview.platform.athenahealth.com/v1/2636801/1/fhir/dstu2/Appointment/13980",
    },
    {
      resource: {
        end: "2024-02-01T09:30:00-05:00",
        type: {
          text: "Follow Up - Patient: Tele-Med",
        },
        participant: [
          {
            status: "accepted",
            actor: {
              display: "Vincent James Fiorenzo",
              reference: "Patient/6",
            },
          },
          {
            status: "accepted",
            actor: {
              display: "Brian Aldred, MD",
              reference: "Practitioner/5",
            },
          },
        ],
        resourceType: "Appointment",
        start: "2024-02-01T09:00:00-05:00",
        status: "booked",
        minutesDuration: 30,
        id: "13978",
      },
      fullUrl:
        "https://api.preview.platform.athenahealth.com/v1/2636801/1/fhir/dstu2/Appointment/13978",
    },
    {
      resource: {
        end: "2024-02-01T09:00:00-05:00",
        type: {
          text: "Follow Up - Patient: Tele-Med",
        },
        participant: [
          {
            status: "accepted",
            actor: {
              display: "Patrick Dolak",
              reference: "Patient/4",
            },
          },
          {
            status: "accepted",
            actor: {
              display: "Brian Aldred, MD",
              reference: "Practitioner/5",
            },
          },
        ],
        resourceType: "Appointment",
        start: "2024-02-01T08:30:00-05:00",
        status: "booked",
        minutesDuration: 30,
        id: "13976",
      },
      fullUrl:
        "https://api.preview.platform.athenahealth.com/v1/2636801/1/fhir/dstu2/Appointment/13976",
    },
  ],
  resourceType: "Bundle",
  id: "urn:uuid:93064938-c0ed-11ee-9957-cb7b0c518f98",
  link: [
    {
      relation: "self",
      url: "https://api.preview.platform.athenahealth.com/v1/2636801/1/fhir/dstu2/Appointment?date=2024-02-01&practitioner=5",
    },
  ],
  total: 5,
};
