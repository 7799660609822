import React from "react";

function useChatScroll(override) {
  const ref = React.useRef();
  React.useEffect(() => {
    if (ref.current && !override) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  });
  return ref;
}

export default useChatScroll;
