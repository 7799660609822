import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3062D4",
    },
    success: {
      main: "#27ae60",
      dark: "1C7D4D",
    },
  },
});

export default theme;
