import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "../../services/SocketContext";

const NoiseAlert = () => {
  const { isSpeaking } = useContext(SocketContext);
  const [isNoiseHigh, setIsNoiseHigh] = useState(false);

  useEffect(() => {
    let mediaStream;
    let audioContext;
    let analyser;
    let processor;
    let offset = 0;
    // let processor;

    if (isSpeaking) {
      const handleAudioProcess = () => {
        let data = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(data);
        let rms = 0;

        for (let i = 0; i < data.length; i++) {
          if (data[i] > 120) data[i] = 120;
          rms += data[i] * data[i];
        }
        rms = Math.sqrt(rms / data.length);
        // console.log("RMS: " + rms);

        offset = parseInt(offset);
        const value = rms + offset;
        if (value > 120) {
          // console.log("Value high: " + value);
          setIsNoiseHigh(true);
        } else {
          // console.log("Value normal: " + value);
          isNoiseHigh && setIsNoiseHigh(false);
        }
      };

      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((stream) => {
          mediaStream = stream;
          audioContext = new AudioContext();
          const source = audioContext.createMediaStreamSource(stream);
          analyser = audioContext.createAnalyser();
          processor = audioContext.createScriptProcessor(2048, 1, 1);

          analyser.smoothingTimeConstant = 0.8;
          analyser.fftSize = 256;

          source.connect(analyser);
          analyser.connect(processor);
          processor.connect(audioContext.destination);

          processor.onaudioprocess = handleAudioProcess;
        })
        .catch((error) => {
          console.error("Error accessing microphone:", error);
        });
    }

    return () => {
      // Clean up resources when the component unmounts
      if (processor) processor.disconnect();
      if (analyser) analyser.disconnect();
      if (audioContext) audioContext.close();
      if (mediaStream) mediaStream.getTracks().forEach((track) => track.stop());
    };
  }, [isSpeaking]);

  return (
    <div>
      {isNoiseHigh && (
        <div style={{ backgroundColor: "beige" }} className="px-2">
          Ambient noise is high. This can affect the quality of transcription.
          Please move to a quieter environment or use the chat option.
        </div>
      )}
    </div>
  );
};

export default NoiseAlert;
