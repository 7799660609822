import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  FormLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import "./InfoModal.css";
import CustomSelect from "../Select/CustomSelect";
import { Icon } from "@virtuslab/tetrisly-icons";
import CloseIcon from "@mui/icons-material/Close";

const MicSelection = ({
  open: isOpen,
  handleClose,
  setDefaultMicrophone,
  microphones,
  defaultMicrophone,
}) => {
  const [open, setOpen] = useState(isOpen);

  const onClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    handleClose();
  };

  const onSend = () => {
    onClose();
  };

  const micOptions = microphones.map((mic) => {
    return {
      label: mic.label,
      value: mic.deviceId,
    };
  });

  const selectedMic = {
    label: defaultMicrophone.label,
    value: defaultMicrophone.deviceId,
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
      fullWidth
    >
      <Grid className="d-flex justify-content-between py-2 px-3 align-items-center primary-bg">
        <div className="d-flex align-items-center">
          <Icon name="20-voice" className="text-white" />
          <Typography className="text-white bold-700 mx-2">
            Default mic selection
          </Typography>
        </div>
        <IconButton onClick={onClose}>
          <CloseIcon className="text-white" />
        </IconButton>
      </Grid>
      <Grid className="mx-4 mt-2" style={{ height: 200 }}>
        <Grid className="my-3">
          <FormLabel className="bold-600">Select Microphone</FormLabel>
          <CustomSelect
            className="my-2"
            placeholder="Select patient language"
            options={micOptions}
            value={selectedMic}
            onChange={(val) => {
              setDefaultMicrophone(val);
            }}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <DialogActions>
          <Button
            className={`button-cancel text-capitalize ${"info-header"}`}
            onClick={onSend}
          >
            Okay
          </Button>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
};

export default MicSelection;
