import { Avatar, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

function Profile() {
  const navigate = useNavigate();
  const { logoutUser, userData } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMyaccount = () => {
    handleClose();
    navigate("/admin/account");
  };

  return (
    <div>
      <IconButton
        edge="start"
        onClick={handleClick}
        sx={{
          //   display: { md: "none" },
          p: 0,
        }}
      >
        <Tooltip title="Open settings">
          <Avatar style={{ width: 32, height: 32 }} alt={""} src={""}>
            {userData?.firstName?.slice(0, 1) ?? "U"}
          </Avatar>
        </Tooltip>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleMyaccount}>My account</MenuItem>
        <MenuItem onClick={logoutUser}>Logout</MenuItem>
      </Menu>
    </div>
  );
}

export default Profile;
