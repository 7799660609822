import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Grid } from "@mui/material";
import Profile from "../../components/Header/Profile";

function Layout({ children }) {
  return (
    <div className="d-md-flex w-100">
      <Sidebar />
      <Grid className="w-100">
        <div className="d-none d-md-flex justify-end mx-2 mt-2">
          <Profile />
        </div>
        {children}
      </Grid>
    </div>
  );
}

export default Layout;
