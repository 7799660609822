import { useContext, useEffect, useState } from "react";
import api from "../services/api";
import {
  refreshError,
  userLogin,
  userLoginFailure,
  setAlertNote,
  fetchUserData,
  logOut,
  setSelected,
  //   updateError,
} from "../redux/reducers/users.reducer";
import { useDispatch, useSelector } from "react-redux";
import { GlobalContext } from "../services/GlobalContext";

export default function useAuth() {
  const dispatch = useDispatch();
  const { userData, error, token, selected } = useSelector(
    (state) => state.user
  );
  const { setError, setIsLoading, isLoading } = useContext(GlobalContext);
  // const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setIsLoading(false);
  };
  const handleOpen = () => {
    setIsLoading(true);
  };

  useEffect(() => {
    dispatch(refreshError());
  }, []);

  const updateAlertNote = (text, type) =>
    dispatch(setAlertNote({ text: text || "", type: type || "" }));

  const userLoginApi = async (data) => {
    return await api
      .post(`/user/login`, data)
      .then((res) => {
        dispatch(userLogin(res?.data));
        return res.data;
      })
      .catch((err) => {
        dispatch(userLoginFailure(err?.Response?.data?.error || err.message));
        setError(err?.Response?.data?.message ?? err.message);
        throw new Error(err);
      });
  };

  const getUserApi = async (data) => {
    return await api
      .get(`/user/verify`, {
        headers: {
          accessToken: data || token,
        },
      })
      .then((res) => {
        dispatch(fetchUserData(res?.data.data));
        return res.data.data;
      })
      .catch((err) => {
        dispatch(userLoginFailure(err.message));
        setError(err?.Response?.data?.message ?? err.message);
        throw new Error(err);
      });
  };

  const updateuserApi = async (data) => {
    setIsLoading(true);
    return await api
      .put(`/user/${userData.userId}`, data, {
        headers: {
          accessToken: token,
        },
      })
      .then((res) => {
        setIsLoading(false);
        dispatch(fetchUserData({ ...userData, ...data }));
        return res.data.data;
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err?.Response?.data?.message ?? err.message);
        throw new Error(err);
      });
  };

  const logoutUser = async () => {
    dispatch(logOut());
  };

  const handleSelected = async (payload) => {
    dispatch(setSelected(payload));
  };

  return {
    userData,
    error,
    userLoginApi,
    loading: isLoading,
    handleClose,
    updateAlertNote,
    getUserApi,
    logoutUser,
    updateuserApi,
    handleSelected,
    selected,
  };
}
