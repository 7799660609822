import React from "react";
import { saveAs } from "file-saver";
import { Button, Typography } from "@mui/material";
import { Icon } from "@virtuslab/tetrisly-icons";
import moment from "moment";

const ExportCSV = ({ data }) => {
  const convertJsonToCsv = () => {
    const headers = Object.keys(data[0]).join(",");
    const rows = data.map((obj) => Object.values(obj).join(",")).join("\n");
    return `${headers}\n${rows}`;
  };

  const handleExport = () => {
    const csvData = convertJsonToCsv();
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, `call_history_${moment().unix()}.csv`);
  };

  return (
    <div>
      <Button
        variant="contained"
        color={"inherit"}
        className="add-new-btn bg-white"
        onClick={handleExport}
      >
        <Icon name="20-upload" />
        &nbsp;
        <Typography className="text-capitalize font-14">
          Export as CSV
        </Typography>
      </Button>
    </div>
  );
};

export default ExportCSV;
