import { ffmpegCoreUrl, silenceRemoveCommand } from "../constants/strings";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { toBlobURL } from "@ffmpeg/util";

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeWords(str) {
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
}

export function getQueryString(obj) {
  return Object.keys(obj)
    .map((key) => obj[key] && key + "=" + obj[key])
    .filter(Boolean)
    .join("&");
}

export const removeSilence = async (audioBlob) => {
  let blob = audioBlob;
  const ffmpeg = new FFmpeg({
    log: true,
  });
  const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
  if (!ffmpeg.loaded) {
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
      wasmURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.wasm`,
        "application/wasm"
      ),
    });
  }
  const buffer = await blob.arrayBuffer();
  console.log({ in: buffer.byteLength });
  await ffmpeg.writeFile("in.wav", new Uint8Array(buffer));
  await ffmpeg.exec([
    "-i", // Input
    "in.wav",
    "-acodec", // Audio codec
    "libmp3lame",
    "-b:a", // Audio bitrate
    "96k",
    "-ar", // Audio sample rate
    "44100",
    "-af", // Audio filter = remove silence from start to end with 2 seconds in between
    silenceRemoveCommand,
    "out.wav", // Output
  ]);
  const out = await ffmpeg.readFile("out.wav");
  console.log({ out: out.buffer.byteLength });
  // 225 seems to be empty mp3 file
  if (out.length <= 225) {
    // ffmpeg.exit();
    return null;
  }
  // ffmpeg.exit();
  blob = new Blob([out.buffer], { type: "audio/wav" });
  return blob;
};
