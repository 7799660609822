const {
  REACT_APP_ATHENA_URL,
  REACT_APP_BASE_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_REDIRECT_URL,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_CLIENT_ID_TWO_LEGGED,
  REACT_APP_CLIENT_SECRET_TWO_LEGGED,
  REACT_APP_SCOPES,
  REACT_APP_GRANT_TYPE,
  REACT_APP_DEV_URL,
  REACT_APP_API_URL,
  REACT_APP_SOCKET,
  REACT_APP_BASE_NAME,
  REACT_APP_API_BASE_URL,
} = process.env;

export const BASE_URL = REACT_APP_API_URL;
export const API_BASE_URL = REACT_APP_API_BASE_URL;
export const BASE_URL_ = REACT_APP_BASE_URL;
export const ATHENA_BASE_URL = REACT_APP_ATHENA_URL;
export const CLIENT_ID = REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = REACT_APP_CLIENT_SECRET;
export const REDIRECT_URL = REACT_APP_REDIRECT_URL;
export const CLIENT_ID_TWO_LEGGED = REACT_APP_CLIENT_ID_TWO_LEGGED;
export const CLIENT_SECRET_TWO_LEGGED = REACT_APP_CLIENT_SECRET_TWO_LEGGED;
export const SCOPES = REACT_APP_SCOPES;
export const GRANT_TYPE = REACT_APP_GRANT_TYPE;
export const DEV_URL = REACT_APP_DEV_URL;
export const SOCKET_PATH = REACT_APP_SOCKET;
export const BASE_NAME = REACT_APP_BASE_NAME;
