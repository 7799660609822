import React, { useContext, useState } from "react";
import {
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemButton,
  Box,
  Divider,
  ListItemIcon,
  Typography,
  Collapse,
  Toolbar,
  Menu,
  Tooltip,
  Button,
  MenuItem,
} from "@mui/material";
import "./MenuHeader.css";
import { useLocation, useNavigate } from "react-router-dom";
import menuOptions from "./menuOptions";
import Down from "@mui/icons-material/ArrowDropDownOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import DownArrow from "@mui/icons-material/KeyboardArrowDownRounded";
import Logo from "../../assets/CareSpeak_Logo.png";
import { Icon } from "@virtuslab/tetrisly-icons";
import { GlobalContext } from "../../services/GlobalContext";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import { useTranslation } from "react-i18next";
import NoiseAlert from "../Noise/Noise";

const MenuHeader = () => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [listData, setListData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { setHasMicAccess, hasMicAccess, setLanguageSetting } =
    useContext(GlobalContext);

  const type = localStorage.getItem("type");

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const handleListItemClick = (path) => {
    navigate(path);
    toggleDrawer(false);
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      // onClick={toggleDrawer(false)}
      // onKeyDown={toggleDrawer(false)}
    >
      <div className="d-flex align-items-center p-2 mx-2">
        <img src={Logo} alt="logo" className="logo" />
      </div>
      <Divider />
      <List>
        {menuOptions.map(({ icon, Icon: CustomIcon, ...i }) => {
          const isSelected = location.pathname === i.path;
          return (
            <div key={i.id}>
              <ListItemButton
                key={i.id}
                className="my-2"
                onClick={() =>
                  i.hasSubsections
                    ? setSelected(i.id)
                    : handleListItemClick(i.path)
                }
              >
                <div>
                  {i?.hasSubsections ? (
                    <Down style={{ fontSize: 16, color: "#7E8B99" }} />
                  ) : (
                    <span className="mx-2"></span>
                  )}
                </div>
                <ListItemIcon>
                  {i?.noIcon ? (
                    icon ? (
                      <Icon name={icon} />
                    ) : (
                      <CustomIcon style={{ color: "#4A545E" }} />
                    )
                  ) : (
                    <img src={Icon} alt={i.label} className="sidebar-icon" />
                  )}
                </ListItemIcon>
                <Typography
                  className={isSelected ? "selected-section" : "section-title"}
                >
                  {t(i.name)}
                </Typography>
              </ListItemButton>
              {i?.hasSubsections ? (
                <Collapse in={selected === i.id} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding className="mx-2">
                    {i?.subSections?.map(({ SubIcon, ...sub }) => {
                      const issubSelected = location.pathname === sub.path;
                      return (
                        <ListItemButton
                          key={sub.id}
                          sx={{ pl: 10 }}
                          onClick={() => handleListItemClick(sub.path)}
                        >
                          <Typography
                            className={
                              issubSelected
                                ? "selected-section"
                                : "section-title"
                            }
                          >
                            {t(sub.name)}
                          </Typography>
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
              ) : null}
            </div>
          );
        })}
      </List>
    </Box>
  );

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const onSettings = (e) => {
    setLanguageSetting(true);
  };

  const retryPermission = async () => {
    await navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setHasMicAccess(true);
      })
      .catch((err) => {
        alert(t("permission.noMicPermissions"));
        setHasMicAccess(false);
      });
  };

  return (
    <>
      <Grid className="header-main mx-3">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(e) => {
                toggleDrawer(true)(e);
              }}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Grid
            role="button"
            onClick={() => navigate("/home")}
            sx={{
              mr: 2,
            }}
            className="align-items-center px-1"
          >
            <img src={Logo} alt="logo" className="logo" />
          </Grid>

          <Drawer
            anchor={"left"}
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            {list()}
          </Drawer>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {menuOptions.map((option, idx) => {
              const { icon, Icon: CustomIcon } = option;
              return (
                <Button
                  key={option.id}
                  disabled={option?.disabled}
                  onClick={(e) => {
                    setListData(option?.subSections || []);
                    option?.subSections
                      ? handleOpenMenu(e)
                      : navigate(option?.path);
                  }}
                  className={[
                    "d-flex my-2",
                    location?.pathname === option?.path ||
                    option?.subSections?.some(
                      (sub) => location?.pathname === sub?.path
                    )
                      ? "selected-option"
                      : "normal-option",
                  ].join(" ")}
                >
                  {option?.noIcon ? (
                    icon ? (
                      <Icon name={icon} />
                    ) : (
                      <CustomIcon style={{ color: "#4A545E" }} />
                    )
                  ) : (
                    <img
                      src={option?.icon}
                      alt={option.label}
                      className="sidebar-icon"
                    />
                  )}
                  <Typography className="px-2 font-14 text-capitalize normal-options">
                    {t(option?.name)}
                  </Typography>
                  {option.hasSubsections && <DownArrow />}
                </Button>
              );
            })}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {listData.map((option) => (
                <MenuItem
                  key={option.id}
                  onClick={() => {
                    // setSelected(option?.name);
                    handleCloseNavMenu();
                    navigate(option?.path);
                  }}
                  disabled={option?.disabled}
                  className={[
                    location?.pathname === option?.path
                      ? "selected-option"
                      : "normal-option",
                  ].join(" ")}
                >
                  <Typography className="normal-options" textAlign="center">
                    {t(option?.name)}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {window.location.pathname.includes("/session") && (
              <div className="d-flex align-items-center">
                {hasMicAccess ? (
                  <div className="mx-2">
                    <MicNoneOutlinedIcon />
                  </div>
                ) : (
                  <Tooltip title="retry mic permission">
                    <IconButton onClick={retryPermission} className="mx-2">
                      <MicOffOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {type === "Provider" && (
                  <>
                    <span className="d-none d-md-flex">|</span>
                    <div className="d-none d-md-flex">
                      <Tooltip title="settings">
                        <IconButton onClick={onSettings} className="mx-2">
                          <Icon name="20-settings" color="#000" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </>
                )}
              </div>
            )}
          </Box>
        </Toolbar>
        <Divider />
        {!hasMicAccess && (
          <div style={{ backgroundColor: "beige" }} className="px-2">
            {t("permission.noMicPermissions")}
            <span
              role="button"
              style={{ color: "blue" }}
              onClick={retryPermission}
              className="mx-2"
            >
              {t("permission.requestPermission")}
            </span>
          </div>
        )}
        <NoiseAlert />
      </Grid>
    </>
  );
};

export default MenuHeader;
