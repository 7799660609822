import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import "./InfoModal.css";
import CustomSelect from "../Select/CustomSelect";
import { languages } from "../../data";
import { SocketContext } from "../../services/SocketContext";
import { GlobalContext } from "../../services/GlobalContext";
import useGlobal from "../../hooks/useGlobal";
// import { useTranslation } from "react-i18next";

const LanguageSelection = ({ open: isOpen, handleClose, sessionId, type }) => {
  // const { i18n } = useTranslation();
  const [open, setOpen] = useState(isOpen);
  const [requireTranslation, setRequireTranslation] = useState("no");
  const [lang, setLang] = useState({ id: 18, label: "English", value: "en" });

  const { onLangSelect, receiver } = useContext(SocketContext);
  const { updateUser } = useGlobal();

  const isRequiredTranslation = requireTranslation === "yes";

  const handleChange = (event) => {
    setRequireTranslation(event.target.value);
    if (event.target.value === "no") {
      setLang({ id: 18, label: "English", value: "en" });
    }
  };

  useEffect(() => {
    let langData = localStorage.getItem(`${sessionId}-lang`);
    if (langData && type !== "Patient") {
      langData = JSON.parse(langData);
      setRequireTranslation(langData?.requireTranslation || "no");

      const currentlang = languages.find(
        (lan) => lan.value === langData?.receiverLanguage
      );

      setLang(currentlang);
    }
  }, []);

  const onClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    handleClose();
  };

  const onSend = () => {
    onClose();
    onLangSelect(lang.value);
    updateUser(receiver?.userId, {
      language: lang?.value || "en",
    });
    localStorage.setItem(
      `${sessionId}-lang`,
      JSON.stringify({
        requireTranslation,
        receiverLanguage: lang?.value || "en",
        sourceLanguage: "en",
      })
    );
  };

  const isDisable =
    requireTranslation === "yes" &&
    (lang?.value === "" || lang?.value === "en");

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
      fullWidth
    >
      <Grid className="mx-4 mt-4">
        <FormControl>
          <FormLabel
            className="bold-600"
            id="demo-controlled-radio-buttons-group"
          >
            Does patient requires translation service?
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            value={requireTranslation}
            onChange={handleChange}
            name="radio-buttons-group"
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        {isRequiredTranslation && (
          <Grid className="my-3">
            <FormLabel className="bold-600">Select patient language</FormLabel>
            <CustomSelect
              className="my-2"
              placeholder="Select patient language"
              options={languages}
              value={lang}
              onChange={(val) => {
                // console.log(val, "val");
                // onLangSelect(val.value);
                setLang(val);
                // i18n.changeLanguage(val.value);
              }}
            />
          </Grid>
        )}
      </Grid>
      <DialogActions>
        <DialogActions>
          <Button
            disabled={isDisable}
            className={`button-cancel text-capitalize ${
              isDisable ? "bg-secondary" : "info-header"
            }`}
            onClick={onSend}
          >
            Save
          </Button>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
};

export default LanguageSelection;
