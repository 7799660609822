import React, { useContext } from "react";
import TranscriptionCard from "./TranscriptionCard";
import "./Cards.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useChatScroll from "../../hooks/useChatScroll";
import { SocketContext } from "../../services/SocketContext";

const Conversation = ({ transcript, userId }) => {
  const { height } = useWindowDimensions();
  const {
    transcripts,
    setTranscripts,
    isSpeaking,
    receiverSpeaking,
    session,
    onUpdateTranscription,
    user,
    receiver,
  } = useContext(SocketContext);
  const ref = useChatScroll();

  const isCompleted = session?.sessionStatus === "COMPLETED";

  const conv_height = height - 520;
  return (
    <div
      ref={ref}
      className="conversation-list"
      style={{
        height: conv_height < 450 ? 350 : conv_height,
      }}
    >
      {[
        ...transcripts,
        isSpeaking && {
          ...transcript,
          type: "transcript",
          loading: true,
          speaker: { ...user },
        },
        receiverSpeaking && {
          ...transcript,
          type: "translation",
          loading: true,
          speakerId: receiver?.userId,
          speaker: { ...receiver },
        },
      ]
        ?.filter(Boolean)
        ?.map((d, index) => {
          return (
            <div key={index} className="px-2">
              <TranscriptionCard
                key={d.transcriptId}
                data={d}
                {...d}
                setTranscripts={setTranscripts}
                isCompleted={isCompleted}
                onUpdateTranscription={onUpdateTranscription}
                idx={index}
                setData={() => {}}
                isEdit={false}
                userId={userId}
                isRealtimeEdit
              />
            </div>
          );
        })}
    </div>
  );
};

export default Conversation;
