import { Grid, IconButton, TextField, Typography } from "@mui/material";
import { Icon } from "@virtuslab/tetrisly-icons";
import React, { useCallback, useState } from "react";
import "./Cards.css";
import { Comment } from "react-loader-spinner";
import moment from "moment";

const TranscriptionCard = ({
  data,
  setTranscripts,
  isCompleted,
  onUpdateTranscription,
  speakerId,
  userId,
  speaker,
  createdAt,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [text, setText] = useState("");

  const isTranslation = speakerId && speakerId !== userId;
  // const time = createdAt ? moment(createdAt).format("hh:mma") : "";

  const handleEdit = useCallback(() => {
    if (!isCompleted) {
      setText(isTranslation ? data.translationText : data.transcriptText);
      setIsEdit(true);
    }
  }, [data.transcriptText, isCompleted]);

  const handleSave = useCallback(() => {
    // console.log(text, "text");
    setTranscripts((prev) =>
      prev.map((p) => {
        if (p.transcriptId === data.transcriptId) {
          return {
            ...p,
            transcriptText: text,
          };
        }
        return p;
      })
    );
    onUpdateTranscription(text, data.transcriptId);
    setIsEdit(false);
  }, [text]);

  const handleCancel = useCallback(() => {
    setIsEdit(false);
  }, []);

  const handleText = (e) => {
    setText(e.target.value);
  };

  return (
    <Grid className="px-4 my-3 d-flex justify-between">
      <div className="d-flex gap-4">
        <div className="w-100">
          <Typography
            className={`bold-700 px-2 ${isTranslation ? "primary-color" : ""}`}
          >
            {isTranslation
              ? `${speaker?.firstName || ""} ${speaker?.lastName || ""}`
              : "You"}
          </Typography>
          {data.loading ? (
            <div className="px-2">
              <Comment
                visible={true}
                height="30"
                width="30"
                ariaLabel="comment-loading"
                wrapperStyle={{}}
                wrapperClass="comment-wrapper"
                color="#fff"
                backgroundColor="#3062d4"
              />
            </div>
          ) : (
            <div>
              {!isEdit ? (
                <Typography className="convo-text mx-2">
                  {isTranslation ? data.translationText : data.transcriptText}
                </Typography>
              ) : (
                <TextField
                  id="filled-textarea"
                  multiline
                  rows={3}
                  className="responsive-textarea-width"
                  // variant="filled"
                  value={text}
                  onChange={handleText}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {/*<div className="font-12 time-color">{time}</div>*/}
      {!isCompleted && !isTranslation && (
        <Grid>
          {!isEdit ? (
            <IconButton onClick={handleEdit}>
              <Icon name="20-edit" />
            </IconButton>
          ) : (
            <div className="d-flex align-items-center">
              <IconButton onClick={handleCancel}>
                <Icon name="20-close" />
              </IconButton>
              <IconButton onClick={handleSave}>
                <Icon name="20-check-large" />
              </IconButton>
            </div>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default TranscriptionCard;
