import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import "./InfoModal.css";
import Markdown from "react-markdown";

const PreviousEncounterModal = ({ open: isOpen, handleClose, note }) => {
  const [open, setOpen] = useState(isOpen);

  const onClose = () => {
    setOpen(false);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <Grid className={`${"info-header"}`}>
        <Grid className="d-flex justify-content-between align-items-center mx-3 my-2">
          <Grid className="d-flex align-items-center">
            <Typography className="cancel-title px-2 text-white">
              Previous Encounter Note
            </Typography>
          </Grid>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className="info-subtext"
        >
          {!!note ? (
            <Markdown>{note}</Markdown>
          ) : (
            <Typography>No previous encounter note found</Typography>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default PreviousEncounterModal;
