import io from "socket.io-client";
import { BASE_URL_, SOCKET_PATH } from "../constants/endpoints";

// Create a socket connection
const socket = io(BASE_URL_, {
  path: SOCKET_PATH, // specify the desired path here
  reconnection: true, // Enable reconnection
  reconnectionAttempts: Infinity, // Number of attempts before giving up
  reconnectionDelay: 1000, // Time to wait before attempting reconnection
  reconnectionDelayMax: 5000, // Maximum time to wait between reconnections
  randomizationFactor: 0.5, // Randomization factor for reconnection delay
});

export default socket;
