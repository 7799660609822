import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import "./InfoModal.css";
import Markdown from "react-markdown";
import MarkdownEditor from "@uiw/react-markdown-editor";

const SOAPModal = ({
  open: isOpen,
  handleClose,
  soapNote,
  setSoapNote,
  handleSendToEHR,
  publishedToEhr,
}) => {
  const [open, setOpen] = useState(isOpen);
  const [isEdit, setIsEdit] = useState(false);
  const [note, setNote] = useState(soapNote ?? "");

  const onClose = () => {
    setOpen(false);
    handleClose();
  };

  const onSave = () => {
    setSoapNote(note);
    setIsEdit(false);
  };

  const onEditClick = () => {
    setIsEdit(true);
  };

  const onChangeText = (e) => {
    setNote(e.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <Grid className={`${"info-header"}`}>
        <Grid className="d-flex justify-content-between align-items-center mx-3 my-2">
          <Grid className="d-flex align-items-center">
            <Typography className="cancel-title px-2 text-white">
              SOAP Note
            </Typography>
          </Grid>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className="info-subtext"
        >
          {isEdit ? (
            // <textarea
            //   value={note}
            //   onChange={onChangeText}
            //   style={{ width: "100%", padding: 5 }}
            //   rows={15}
            // />
            <MarkdownEditor
              value={note}
              onChange={(value, viewUpdate) => {
                setNote(value);
              }}
            />
          ) : (
            // <Typography className="font-14">
            //   {note?.replace(/\n/g, "<br>")}
            // </Typography>
            // <Typography
            //   variant="body1"
            //   dangerouslySetInnerHTML={{ __html: note?.replace(/\n/g, "<br>") }}
            // />
            <Markdown>{note}</Markdown>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="justify-content-center">
        <DialogActions>
          <Button
            onClick={isEdit ? onSave : onEditClick}
            variant="contained"
            className="px-5 py-2"
          >
            <Typography className="bold-600 text-capitalize">
              {isEdit ? "Save SOAP note" : "Edit SOAP note"}
            </Typography>
          </Button>
          <Button
            disabled={isEdit || publishedToEhr}
            onClick={(e) => handleSendToEHR(e, soapNote)}
            variant="contained"
            className="px-5 py-2"
          >
            <Typography className="bold-600 text-capitalize">
              Publish SOAP note to EHR
            </Typography>
          </Button>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
};

export default SOAPModal;
