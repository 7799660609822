import React, { useContext, useEffect, useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import "./CallHistory.css";
import { Icon } from "@virtuslab/tetrisly-icons";
import Paper from "@mui/material/Paper";
import { GlobalContext } from "../../services/GlobalContext";
import moment from "moment";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import ExportCSV from "../../components/ExportCSV/ExportCSV";
import useGlobal from "../../hooks/useGlobal";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import CustomSelect from "../../components/Select/CustomSelect";

const CallHistory = () => {
  const location = useLocation();
  const { userData } = useAuth();

  const dateFilter = [
    { id: 1, label: "Today", value: "Today" },
    { id: 2, label: "This Month", value: "This Month" },
    { id: 3, label: "All", value: "All" },
  ];

  const { sessions, setIsLoading, setError, users } = useContext(GlobalContext);
  const { getSessions, getTranscripts, getUsers } = useGlobal();
  const [provider, setProvider] = useState(null);
  const [patient, setPatient] = useState(null);
  const [date, setDate] = useState(dateFilter[0]);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getSessions({
      filter: date?.value,
      providerUserId: provider?.value,
      patient: patient?.value,
    });
  }, [provider, patient, date]);

  const providersData = [
    { name: "Select Provider", userId: "", userType: "PROVIDER" },
    ...users,
  ]
    .filter((user) => user.userType === "PROVIDER")
    .map((user) => {
      return {
        label: user.name,
        value: user.userId,
      };
    });

  const patientsData = [
    { name: "Select Patient", userId: "", userType: "PATIENT" },
    ...users,
  ]
    .filter((user) => user.userType === "PATIENT")
    .map((user) => {
      return {
        label: user.name,
        value: user.userId,
      };
    });

  const handleDownload = async (e, session) => {
    const { sessionId } = session;
    try {
      setIsLoading(true);
      const pdf = new jsPDF("p", "pt", "a4");
      const columns = ["Timestamp", "Name", "Transcript"];
      const data = await getTranscripts({ sessionId });
      if (!data || !data.length) {
        setError("No transcripts found for this session!");
        return null;
      }

      const rows = data.map((i) => {
        return [
          i.createdAt,
          `${i?.speaker?.firstName || ""} ${i?.speaker?.lastName || ""}`,
          i?.speakerId && i.speakerId !== session?.providerUserId
            ? i?.translationText
            : i?.transcriptText,
        ];
      });

      pdf.text(235, 40, "Call Transcript");
      pdf.autoTable(columns, rows, {
        startY: 65,
        theme: "grid",
        styles: {
          font: "times",
          halign: "center",
          cellPadding: 3.5,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
        },
        headStyles: {
          textColor: [0, 0, 0],
          fontStyle: "normal",
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [166, 204, 247],
        },
        alternateRowStyles: {
          fillColor: [212, 212, 212],
          textColor: [0, 0, 0],
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
        },
        rowStyles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
        },
        tableLineColor: [0, 0, 0],
      });
      const pdfData = pdf.output("blob");
      setIsLoading(false);
      saveAs(pdfData, `call_history_${moment().unix()}.pdf`);
    } catch (error) {
      setError(error.message);
    }
  };

  const rows = sessions?.map((session) => {
    const { provider, patient, createdAt, sessionId, duration } = session;
    const providerName = `${provider?.firstName || ""} ${
      provider.lastName || ""
    }`;
    const patientName = `${patient?.firstName || ""} ${patient.lastName || ""}`;
    return {
      Date: moment(createdAt).format("MMM DD, YYYY"),
      Provider: providerName,
      Patient: patientName,
      Duration: duration,
      SessionID: sessionId,
      more: (
        <Tooltip title="Download">
          <IconButton onClick={(e) => handleDownload(e, session)}>
            <Icon name="20-download" />
          </IconButton>
        </Tooltip>
      ),
    };
  });

  if (!userData) {
    return <Navigate to={"/"} replace={location} />;
  }

  return (
    <div className="d-md-flex">
      <Grid className="p-4 w-100">
        <div className="d-flex justify-content-between align-items-center">
          <Grid>
            <Typography className="sessions-header bold-600">
              CareSpeak Sessions
            </Typography>
            <Typography className="sessions-heading-caption">
              Review your organization’s call history
            </Typography>
          </Grid>
          <Grid>
            <ExportCSV
              data={rows?.map((row) => {
                const { more, ...rest } = row;
                return rest;
              })}
            />
          </Grid>
        </div>
        <Divider className="my-2" />
        <Grid
          container
          className="d-flex gap-2 align-items-center py-3 flex-wrap"
        >
          <CustomSelect
            width={300}
            // isMulti
            isClearable
            isFilter
            options={providersData}
            value={provider}
            onChange={(val) => {
              setProvider(val);
            }}
            placeholder={"Select Provider"}
          />
          <CustomSelect
            width={300}
            // isMulti
            isClearable
            isFilter
            options={patientsData}
            value={patient}
            onChange={(val) => {
              setPatient(val);
            }}
            placeholder={"Select Patient"}
          />
          <CustomSelect
            width={300}
            // isMulti
            isClearable
            isFilter
            options={dateFilter}
            value={date}
            onChange={(val) => {
              setDate(val);
            }}
            placeholder={"Select Date"}
          />
        </Grid>
        <Grid className="mb-4">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#F5F7F9" }}>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Provider</TableCell>
                  <TableCell>Patient</TableCell>
                  <TableCell>Duration</TableCell>
                  <TableCell align="right">Session ID</TableCell>
                  <TableCell align="right">Transcript</TableCell>
                  {/* <TableCell align="right"></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row) => (
                  <TableRow
                    key={row.sessionId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.Date}
                    </TableCell>
                    <TableCell>{row.Provider}</TableCell>
                    <TableCell>{row.Patient}</TableCell>
                    <TableCell>{row.Duration}</TableCell>
                    <TableCell align="right">{row.SessionID}</TableCell>
                    <TableCell align="right">{row.more}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default CallHistory;
