import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import "./InfoModal.css";
import { Form } from "react-bootstrap";

const InviteUser = ({ open: isOpen, handleClose, handleInviteSend }) => {
  const [open, setOpen] = useState(isOpen);
  const [email, setEmail] = useState("");

  const onClose = () => {
    setOpen(false);
    handleClose();
  };

  const onSend = () => {
    onClose();
    handleInviteSend({ email });
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
      fullWidth
    >
      <Grid>
        <Grid className="d-flex justify-content-between align-items-center mx-3 my-2">
          <Grid className="d-flex align-items-center">
            <Typography className="cancel-title px-2 bold-600">
              Invite user
            </Typography>
          </Grid>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: "#000" }} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid className="mx-4">
        <Form.Label htmlFor="inputPassword5">Enter email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email address"
          id="email"
          aria-describedby="emailHelpBlock"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Grid>
      <DialogActions>
        <DialogActions>
          <Button
            className={`button-cancel text-capitalize ${"info-header"}`}
            onClick={onSend}
          >
            Send an invitation
          </Button>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUser;
