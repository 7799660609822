import { Switch } from "@mui/material";
import { useTranslation } from "react-i18next";

const SwitchText = ({ inputType, handleChange }) => {
  const { t } = useTranslation();
  return (
    <div className="mx-2">
      <span className="font-16 bold-600">{t("homeAction.speak")}</span>
      <Switch
        checked={inputType === "text"}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
      />
      <span className="font-16 bold-600">{t("homeAction.chat")}</span>
    </div>
  );
};

export default SwitchText;
