import { Card, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { Icon } from "@virtuslab/tetrisly-icons";
import React from "react";
import "./AppointmentsCard.css";

const AppointmentsCard = ({ startCall, data }) => {
  const app = data?.resource || {};
  const ProviderID = app.participant?.[1]?.actor?.reference?.split("/")?.[1];
  const ProviderName = app.participant?.[1]?.actor?.display;
  const PatientID = app.participant?.[0]?.actor?.reference?.split("/")?.[1];
  const PatientName = app.participant?.[0]?.actor?.display;
  const startDate = app.start?.slice(0, 10);
  const appId = app.id;
  const isCompleted = data?.sessionStatus === "COMPLETED";
  const isStarted = data?.sessionStatus === "STARTED";
  const sessionId = data?.sessionId;

  const handleStart = (e) => {
    startCall(e, {
      appId,
      PatientName,
      PatientID,
      ProviderName,
      ProviderID,
      sessionId,
      startDate,
    });
  };

  return (
    <div className="col-md-6">
      <Card className={`p-3 ${isCompleted ? "completed-card" : ""}`}>
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-2 align-items-center">
            <Icon name="20-user" />

            <Typography className="nameText">{PatientName}</Typography>
          </div>
          {isCompleted ? (
            <Tooltip title="Session completed">
              <IconButton disableRipple>
                <Icon name="20-check-circle-fill" color="#27ae60" />
              </IconButton>
            </Tooltip>
          ) : (
            <Icon name="20-info-circle" />
          )}
        </div>
        <Divider className="my-2" variant="fullWidth" component="p" />
        <Typography>Patient ID: {PatientID}</Typography>
        <div className="d-flex gap-4 mt-3">
          <button
            className={`btn p-1 px-2 ${
              isStarted
                ? "join-call"
                : isCompleted
                ? "completed-link"
                : "start-call"
            }`}
            onClick={handleStart}
          >
            <Typography className="text-white font-14">
              {isStarted
                ? "Join Session"
                : isCompleted
                ? "View Session"
                : "Start Session"}
            </Typography>
          </button>
        </div>
      </Card>
    </div>
  );
};

export default AppointmentsCard;
