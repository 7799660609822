import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import "./Users.css";
import { Icon } from "@virtuslab/tetrisly-icons";
import Paper from "@mui/material/Paper";
import { GlobalContext } from "../../services/GlobalContext";

import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useGlobal from "../../hooks/useGlobal";
import InviteUser from "../../components/Modals/InviteUser";
import { capitalizeFirstLetter, capitalizeWords } from "../../utils/utils";

const Users = () => {
  const location = useLocation();
  const { userData } = useAuth();

  const { portalUsers } = useContext(GlobalContext);
  const { getPortalUsers, inviteUser } = useGlobal();

  const [inviteOpen, setInviteOpen] = useState(false);

  useEffect(() => {
    getPortalUsers();
  }, []);

  const handleClose = () => {
    setInviteOpen(false);
  };

  const handleInvite = () => {
    setInviteOpen(true);
  };

  const handleInviteSend = async ({ email }) => {
    setInviteOpen(false);
    await inviteUser({
      email,
      userType: "ADMIN",
      orgId: userData?.org?.Id,
    });
  };

  const rows = portalUsers?.map((user) => {
    return {
      ...user,
      name: capitalizeWords(user.name),
      type: capitalizeFirstLetter(user.type),
      more: (
        <Tooltip title="Download">
          <IconButton>
            <Icon name="20-more-horizontal" />
          </IconButton>
        </Tooltip>
      ),
    };
  });

  if (!userData) {
    return <Navigate to={"/"} replace={location} />;
  }

  return (
    <div className="d-md-flex">
      <Grid className="p-4 w-100">
        <div className="d-flex justify-content-between align-items-center">
          <Grid>
            <Typography className="sessions-header bold-600">
              CareSpeak Users
            </Typography>
            <Typography className="sessions-heading-caption">
              Review your organization’s users
            </Typography>
          </Grid>
        </div>
        <Divider className="my-2" />
        <Grid
          container
          className="d-flex gap-2 align-items-center justify-content-between py-3 flex-wrap"
        >
          <Grid>
            {/* {filters?.map(({ filter, id, value, icon }) => {
            return (
              // <Grid item sm={6} md={3} lg={2} key={id}>
              <Filter key={id} filter={filter} value={value} icon={icon} />
              // </Grid>
            );
          })} */}
          </Grid>
          <Grid className="d-flex gap-3 align-items-center">
            {/* <Grid className="d-flex gap-1 align-items-center" role="button">
              <Icon name="20-filter" className="filter-icon primary-color" />
              <Typography className="font-14 primary-color">Filters</Typography>
            </Grid> */}
            <Button
              variant="contained"
              color={"primary"}
              className="add-new-btn px-3 mt-2"
              onClick={handleInvite}
            >
              <Typography className="text-capitalize font-14">
                Invite user
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid className="mb-4">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#F5F7F9" }}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Type</TableCell>
                  {/* <TableCell align="right"></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row) => (
                  <TableRow
                    key={row.userId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    {/* <TableCell align="right">{row.more}</TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {inviteOpen && (
          <InviteUser
            open={inviteOpen}
            handleClose={handleClose}
            handleInviteSend={handleInviteSend}
          />
        )}
      </Grid>
    </div>
  );
};

export default Users;
