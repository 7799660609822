import React, { useContext, useEffect } from "react";
import MenuHeader from "../../components/Header/MenuHeader";
import { Grid, Typography } from "@mui/material";
import axios from "axios";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import {
  ATHENA_BASE_URL,
  BASE_URL,
  CLIENT_ID,
  CLIENT_SECRET,
  REDIRECT_URL,
  DEV_URL,
  BASE_NAME,
} from "../../constants/endpoints";
import moment from "moment";
import { Icon } from "@virtuslab/tetrisly-icons";
import AppointmentsCard from "../../components/Cards/AppointmentsCard";
import copy from "copy-to-clipboard";
import { GlobalContext } from "../../services/GlobalContext";
// import { dummy_appointments } from "../../data";
import api from "../../services/api";
import { SocketContext } from "../../services/SocketContext";
import useGlobal from "../../hooks/useGlobal";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    setIsLoading,
    setTokenData,
    setSessions,
    sessions,
    appointments,
    setAppointments,
    provider,
    setProvider,
    tokenData,
    setError,
    providerUserName,
    setProviderUserName,
    providersList,
    selectedProvider,
    setSelectedProvider,
    subscription,
    isLoading,
  } = useContext(GlobalContext);
  const { setSession, setSourceLanguage } = useContext(SocketContext);
  const {
    parseToken,
    getProviders,
    getProviderByUsername,
    getSubscriptions,
    validateSubscription,
  } = useGlobal();

  // console.log(tokenData, "tokenData");

  const isValidSubscription = validateSubscription(subscription);

  const location = window.location;
  useEffect(() => {
    const init = async () => {
      if (
        location.pathname ===
        `${BASE_NAME === "/carespeak" ? "/carespeak/home" : "/home"}`
      ) {
        // Parse the query parameters from the URL
        const params = new URLSearchParams(location.search);

        const code = params.get("code");
        const tokenUrl = `${ATHENA_BASE_URL}/oauth2/v1/token`;
        if (code) {
          setIsLoading(true);
          axios
            .post(
              tokenUrl,
              `grant_type=authorization_code&code=${code}&redirect_uri=${REDIRECT_URL}&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}`,
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            )
            .then(async (r) => {
              setTokenData(r.data);
              setIsLoading(false);
            })
            .catch((err) => {
              setError(err.message);
              setTokenData(null);
              setIsLoading(false);
              navigate("/authError");
            });
        }
      }
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      const { username } = parseToken(tokenData);
      const provider = await getProviderByUsername({
        username,
      });
      if (provider?.billing?.subId) {
        await getSubscriptions(provider?.billing?.subId);
      }
      getProviders({ orgId: provider.orgId });
    };
    if (tokenData) {
      init();
    }
  }, [tokenData]);

  const getAppointments = async () => {
    try {
      setIsLoading(true);
      const { practiceId, deptId, username } = parseToken(tokenData);
      const practiceid = practiceId;
      const departmentid = deptId;
      const date = moment().format("YYYY-MM-DD");
      let providerId = provider?.ehrId;
      let userId = provider?.userId;
      if (!provider) {
        // const userName = "dtester2";
        const providerRes = await getProviderByUsername({ username });
        providerId = providerRes.data.data?.ehrId;
        userId = providerRes.data.data.userId;
      }
      // console.log(providerId, "providerId", provider);
      const res = await api.get(
        `/athena/appointments?practiceid=${practiceid}&departmentid=${departmentid}&date=${date}&providerId=${providerId}`
      );
      setAppointments(res.data.data);
      setIsLoading(false);
      return {
        appointments: res.data.data,
        userId: userId,
      };
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const getSessions = async () => {
      try {
        const apps = await getAppointments();
        const sessionUrl = `/user/sessions/${provider?.userId || apps?.userId}`;
        const sessionsData = await api.get(sessionUrl);
        setSessions(sessionsData.data.data);
        const data = sessionsData.data.data;
        let tmpData = apps.appointments;
        if (!tmpData) return;
        tmpData.entry = tmpData?.entry?.map((entry) => {
          const session = data?.find(
            (s) => String(s.ehrAppointmentId) === entry.resource.id
          );

          if (session) {
            return {
              ...entry,
              sessionStatus: session.sessionStatus,
              sessionId: session.sessionId,
            };
          }
          return { ...entry, sessionStatus: "SCHEDULED" };
        });
        tmpData?.entry?.sort((a, b) => {
          const statusOrder = { STARTED: 1, SCHEDULED: 2, COMPLETED: 3 };
          return (
            statusOrder?.[a?.sessionStatus] - statusOrder?.[b?.sessionStatus]
          );
        });
        setAppointments(tmpData);
      } catch (error) {
        setError(error?.response?.data?.message ?? error.message);
      }
    };
    tokenData &&
      (provider?.ehrId || provider?.userType === "PROVIDER") &&
      getSessions();
    // }
  }, [tokenData, provider]);

  const createSession = async (data) => {
    try {
      const {
        appId,
        PatientName,
        PatientID,
        ProviderName,
        ProviderID,
        startDate,
      } = data;
      const { practiceId } = parseToken(tokenData);
      const providerFullName = ProviderName.split(" ");
      const providerFirstName = providerFullName?.[0];
      const providerLastName = providerFullName?.[1];

      const patientFullName = PatientName.split(" ");
      const patientFirstName = patientFullName?.[0];
      const patientLastName = patientFullName?.[1];
      const body = {
        ehrAppointmentId: appId,
        orgId: provider?.orgId,
        startDate,
        provider: {
          userType: "PROVIDER",
          ehrId: provider?.ehrId || ProviderID,
          firstName: providerFirstName,
          lastName: providerLastName,
          practiceId: practiceId,
        },
        patient: {
          userType: "PATIENT",
          ehrId: PatientID,
          firstName: patientFirstName,
          lastName: patientLastName,
          practiceId: practiceId,
        },
        staffUserId: provider.userId,
      };
      setIsLoading(true);
      const res = await axios.post(`${BASE_URL}/session/`, body);
      setSession(res?.data?.data);
      return res?.data?.data;
    } catch (error) {
      setError("Unexpected error: Session creation failed!");
      return null;
    }
  };

  const startCall = async (e, payload) => {
    let data = payload;
    if (!data.sessionId) {
      if (provider.ehrId === undefined) {
        alert("Unexpected error: Provider Id not found");
        return null;
      }
      setSourceLanguage(null);
      data = await createSession(payload);
    }
    setIsLoading(false);
    if (data) {
      localStorage.setItem("type", "Provider");
      navigate(`/session/${data?.sessionId}?auth=1`);
    }
  };

  const copyLink = async (payload) => {
    const data = await createSession(payload);
    setIsLoading(false);
    if (data) {
      copy(`${DEV_URL}/session/${data?.sessionId}`);
    }
    return data;
  };

  const { username } = parseToken(tokenData);

  return (
    <div>
      <MenuHeader />
      <div
        className="p-4"
        style={{
          height: "90vh",
        }}
      >
        <Typography className="text-d bold-700 greeting-title">
          {`${t("homeAction.greeting2")} ${
            provider?.lastName || tokenData?.username || ""
          } 👋`}
        </Typography>
        <Typography style={{ color: "#5D5D5C" }}>
          {moment().format("MMMM DD, YYYY")}
        </Typography>
        {provider?.userType === "ADMIN" && (
          <Divider className="my-3" variant="fullWidth" component="p" />
        )}
        {provider?.userType === "ADMIN" && isValidSubscription && (
          <Grid container>
            <div className="gap-2 d-flex align-items-center">
              <Icon name="20-user" className="text-primary" />
              <Typography className="font-16 bold-700 text-primary">
                Select Provider
              </Typography>
            </div>
            <Grid sm={12} md={3} className=" mx-2">
              <Form.Select
                value={selectedProvider}
                onChange={(e) => {
                  setProviderUserName(provider.ehrUsername);
                  setSelectedProvider(e.target.value);
                  const prov = providersList.find(
                    (pl) => pl.ehrUsername === e.target.value
                  );
                  setProvider((prev) =>
                    prev?.userType === "ADMIN"
                      ? { ...prev, ehrId: prov?.ehrId }
                      : prov
                  );
                }}
              >
                <option value="">Select Provider</option>
                {providersList?.map((pl, idx) => {
                  return (
                    <option key={idx} value={pl.ehrUsername}>
                      {`${pl.firstName} ${pl.lastName}`}
                    </option>
                  );
                })}
              </Form.Select>
            </Grid>
          </Grid>
        )}
        {isValidSubscription ? (
          <>
            <Divider className="my-3" variant="fullWidth" component="p" />
            <div className="gap-2 d-flex align-items-center">
              <Icon name="20-calendar" className="text-primary" />
              <Typography className="font-24 bold-700 text-primary">
                Todays Appointments
              </Typography>
            </div>
            <div>
              {appointments?.entry?.map((d, idx) => {
                return (
                  <div className="py-3" key={idx}>
                    <AppointmentsCard
                      data={d}
                      startCall={startCall}
                      copyLink={copyLink}
                    />
                  </div>
                );
              })}
            </div>
          </>
        ) : !isLoading ? (
          <div>
            You are not subscribed to CareSpeak, Please subscribe / Reach out to
            you Administrator. Your username - ({username || ""})
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Dashboard;
