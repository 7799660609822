import React, { createContext, useState } from "react";

const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [sessions, setSessions] = useState(null);
  const [token, setToken] = useState(null);
  const [tokenData, setTokenData] = useState(null);
  const [appointments, setAppointments] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [inputType, setInputType] = useState("audio");
  const [encounter, setEncounter] = useState(null);
  const [hasMicAccess, setHasMicAccess] = useState(false);
  const [provider, setProvider] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [product, setProduct] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [portalUsers, setPortalUsers] = useState([]);
  const [providersList, setProvidersList] = useState([]);
  const [users, setUsers] = useState([]);
  const [languageSetting, setLanguageSetting] = useState(false);
  const [isTranslation, setIsTranslation] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [endCall, setEndCall] = useState(false);

  const type = localStorage.getItem("type");
  const providerUserName = localStorage.getItem("providerUserName");

  const setProviderUserName = (val) =>
    localStorage.setItem("providerUserName", val);

  return (
    <GlobalContext.Provider
      value={{
        type,
        setSession,
        session,
        setToken,
        token,
        setIsLoading,
        isLoading,
        setTokenData,
        tokenData,
        sessions,
        setSessions,
        appointments,
        setAppointments,
        inputType,
        setInputType,
        encounter,
        setEncounter,
        hasMicAccess,
        setHasMicAccess,
        provider,
        setProvider,
        paymentMethods,
        setPaymentMethods,
        subscription,
        setSubscription,
        product,
        setProduct,
        error,
        setError,
        invoices,
        setInvoices,
        portalUsers,
        setPortalUsers,
        providerUserName,
        setProviderUserName,
        providersList,
        setProvidersList,
        currentUser,
        setCurrentUser,
        selectedProvider,
        setSelectedProvider,
        users,
        setUsers,
        languageSetting,
        setLanguageSetting,
        isMuted,
        setIsMuted,
        endCall,
        setEndCall,
        isTranslation,
        setIsTranslation,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalProvider, GlobalContext };
