import React from "react";
import { Divider, Typography } from "@mui/material";
import moment from "moment/moment";

const ModalTranscript = ({
  createdAt,
  isEdit,
  data,
  transcriptId,
  setData,
  speakerId,
  userId,
  translationText,
  transcriptText,
  speaker,
  idx,
}) => {
  const isTranslation = speakerId && speakerId !== userId;
  const time = createdAt ? moment(createdAt).format("hh:mma") : "";

  const onChangeText = (e) => {
    const val = e.target.value;
    let tmpData = [...data];
    tmpData = tmpData.map((tmp) => {
      if (tmp.transcriptId === transcriptId) {
        return {
          ...tmp,
          translationText: isTranslation ? val || "" : tmp.translationText, // Use an empty string if there are no more lines
          transcriptText: isTranslation ? tmp.transcriptText : val || "", // Use an empty string if there are no more lines
        };
      }
      return tmp;
    });
    setData([...tmpData]);
  };

  return (
    <>
      <Divider />
      <div
        className="d-flex align-items-center justify-content-between m-3"
        id={`transcript_${idx}`}
      >
        <div>
          <Typography
            className={`bold-700 ${isTranslation ? "primary-color" : ""}`}
          >
            {`${speaker?.firstName || ""} ${speaker?.lastName || ""}`}
          </Typography>
          {isEdit ? (
            <textarea
              value={isTranslation ? translationText : transcriptText}
              onChange={onChangeText}
              style={{ width: "450px", padding: 5 }}
            />
          ) : (
            <Typography className="font-14">
              {isTranslation ? translationText : transcriptText}
            </Typography>
          )}
        </div>
        <div className="font-12 time-color">{time}</div>
      </div>
    </>
  );
};

export default ModalTranscript;
