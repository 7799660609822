import React, { useContext } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// import Login from "../Login/index";
// import Transcribe from "../Transcribe";
import HomeScreen from "../screens/HomeScreen/HomeScreen";
// import NotFound from "../screens/NotFound/NotFound";
import Dashboard from "../screens/Dashboard/Dashboard";
// import { Backdrop, CircularProgress } from "@mui/material";
import { GlobalContext } from "../services/GlobalContext";
import Help from "../screens/Help/Help";
import AuthError from "../screens/Errors/AuthError";
import Login from "../screens/login/login";
import Billing from "../screens/Billing/Billing";
import CallHistory from "../screens/CallHistory/CallHistory";
import Invoices from "../screens/Invoices/Invoices";
import Loading from "../components/Loading";
import InfoModal from "../components/Modals/InfoModal";
import Users from "../screens/Users/Users";
import Signup from "../screens/signup/signup";
import Company from "../screens/Company/Company";
import ConsecutiveSnackbars from "../components/Snackbar/Snackbar";
import Account from "../screens/Account/Account";
import { encounterError } from "../constants/strings";
import AdminLayout from "./AdminLayout";

const MainRoutes = () => {
  const location = useLocation();
  const { isLoading, setIsLoading, error, setError } =
    useContext(GlobalContext);
  const handleClose = () => {
    setIsLoading(false);
  };
  const onClose = () => {
    setError("");
    if (error === encounterError) {
      return <Navigate to={"/home"} replace={location} />;
    }
  };
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup/:inviteId" element={<Signup />} />
        {/* <Route path="/" element={<Login />} /> */}
        <Route path="/home" element={<Dashboard />} />
        <Route path="/help" element={<Help />} />
        <Route element={<AdminLayout />}>
          <Route path="/admin">
            <Route path="billing" element={<Billing />} />
            <Route path="home" element={<CallHistory />} />
            <Route path="invoices" element={<Invoices />} />
            <Route path="users" element={<Users />} />
            <Route path="company" element={<Company />} />
            <Route path="account" element={<Account />} />
          </Route>
        </Route>
        <Route path="/authError" element={<AuthError />} />
        <Route path="/session/:sessionId" element={<HomeScreen />} />
        {/* <Route path="/dashboard" element={<Transcribe />} /> */}
        {/* <Route path="*" element={<NotFound isAuth={isAuth} />} /> */}
      </Routes>
      {isLoading && <Loading loading={isLoading} handleClose={handleClose} />}
      {error && (
        <InfoModal
          open={error}
          handleClose={onClose}
          title="Error"
          subtext={error}
          type="error"
        />
      )}
      <ConsecutiveSnackbars />
    </>
  );
};

export default MainRoutes;
