export const REQUEST_TIME_OUT_ERROR_MESSAGE = "Request timed out";
export const ALL_ZONES = "All Zones";
export const ffmpegCoreUrl =
  "https://unpkg.com/@ffmpeg/core-st@0.11.1/dist/ffmpeg-core.js";

export const silenceRemoveCommand =
  "silenceremove=start_periods=1:stop_periods=-1:start_threshold=-30dB:stop_threshold=-30dB:start_silence=2:stop_silence=2";

export const encounterError =
  "The Patient is not Checked In. Kindly complete the Patients Check-In in Athena and then start the session in CareSpeak.";
