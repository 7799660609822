import { Icon } from "@virtuslab/tetrisly-icons";

export const sidebarData = [
  {
    id: 1,
    title: "Call History",
    Icon: (props) => <Icon name="20-home" {...props} />,
    isDisabled: false,
    subSections: [],
    path: "/admin/home",
  },
  {
    id: 2,
    title: "Administrator",
    Icon: (props) => <Icon name="20-users" {...props} />,
    isDisabled: false,
    subSections: [
      {
        id: 1,
        title: "Company",
        Icon: null,
        isDisabled: false,
        path: "/admin/company",
      },
      {
        id: 2,
        title: "Users",
        Icon: null,
        isDisabled: false,
        path: "/admin/users",
      },
      {
        id: 3,
        title: "Invoices",
        Icon: null,
        isDisabled: false,
        path: "/admin/invoices",
      },
      {
        id: 4,
        title: "Billing",
        Icon: null,
        isDisabled: false,
        path: "/admin/billing",
      },
    ],
  },
  {
    id: 3,
    title: "Reporting (coming soon!)",
    Icon: (props) => <Icon name="20-chart-bar-box" {...props} />,
    isDisabled: true,
    subSections: [],
  },
];

export const resourcesData = [
  {
    id: 4,
    title: "User Guides",
    Icon: (props) => <Icon name="20-file" {...props} />,
    isDisabled: false,
    subSections: [],
  },
  {
    id: 5,
    title: "Support",
    Icon: (props) => <Icon name="20-users" {...props} />,
    isDisabled: false,
    subSections: [],
    link: "http://www.caresms.io/",
  },
];
