import React, { useContext, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import "./InfoModal.css";
import useGlobal from "../../hooks/useGlobal";
import { SocketContext } from "../../services/SocketContext";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbDownRoundedIcon from "@mui/icons-material/ThumbDownRounded";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import { useTranslation } from "react-i18next";

const FeedbackModal = ({ open: isOpen, handleClose, translationEnabled }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(isOpen);
  const [feedbackText, setFeedbackText] = useState("");
  const [transcriptionRating, setTranscriptionRating] = useState(undefined);
  const [translationRating, setTranslationRating] = useState(undefined);
  const { postFeedback } = useGlobal();
  const { session } = useContext(SocketContext);

  const isProvider = localStorage.getItem("type") === "Provider";

  const onClose = () => {
    setOpen(false);
    handleClose();
  };

  const onChangeText = (e) => {
    setFeedbackText(e.target.value);
  };

  const onChangeTranscriptionRating = (e, val) => {
    transcriptionRating === val
      ? setTranscriptionRating("")
      : setTranscriptionRating(val);
  };

  const onChangeTranslationRating = (e, val) => {
    translationRating === val
      ? setTranslationRating("")
      : setTranslationRating(val);
  };

  const onConfirm = async () => {
    await postFeedback({
      feedbackText,
      transcriptionRating,
      translationRating,
      sessionId: session.sessionId,
    });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
      fullWidth
    >
      <Grid className="text-center">
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="info-subtext"
          >
            <Typography className="font-24 bold-600">
              {t("feedback.opinionMatters")}
            </Typography>
            <Typography className="font-14">
              {t("feedback.feedbackText")}
            </Typography>
          </DialogContentText>
          <Grid className="my-4">
            <Typography className="font-18 bold-600">
              {t("feedback.rateTranscription")}
            </Typography>
            <div className="d-flex gap-2 justify-content-center">
              <IconButton
                onClick={(e) => onChangeTranscriptionRating(e, "thumb-up")}
              >
                {transcriptionRating === "thumb-up" ? (
                  <ThumbUpRoundedIcon className="primary-color" />
                ) : (
                  <ThumbUpOutlinedIcon />
                )}
              </IconButton>
              <IconButton
                onClick={(e) => onChangeTranscriptionRating(e, "thumb-down")}
              >
                {transcriptionRating === "thumb-down" ? (
                  <ThumbDownRoundedIcon className="primary-color" />
                ) : (
                  <ThumbDownOutlinedIcon />
                )}
              </IconButton>
            </div>
          </Grid>
          {translationEnabled && (
            <Grid className="my-4">
              <Typography className="font-18 bold-600">
                {t("feedback.rateTranslation")}
              </Typography>
              <div className="d-flex gap-2 justify-content-center">
                <IconButton
                  onClick={(e) => onChangeTranslationRating(e, "thumb-up")}
                >
                  {translationRating === "thumb-up" ? (
                    <ThumbUpRoundedIcon className="primary-color" />
                  ) : (
                    <ThumbUpOutlinedIcon />
                  )}
                </IconButton>
                <IconButton
                  onClick={(e) => onChangeTranslationRating(e, "thumb-down")}
                >
                  {translationRating === "thumb-down" ? (
                    <ThumbDownRoundedIcon className="primary-color" />
                  ) : (
                    <ThumbDownOutlinedIcon />
                  )}
                </IconButton>
              </div>
            </Grid>
          )}
          <div className="my-4">
            <TextField
              id="filled-textarea"
              label={t("feedback.feedbackPlaceholder")}
              multiline
              className="w-75"
              value={feedbackText}
              onChange={onChangeText}
            />
          </div>
          <div className="align-items-center">
            <Button
              className={`button-cancel info-header text-capitalize w-75`}
              onClick={onConfirm}
            >
              {t("feedback.confirmRating")}
            </Button>
            <Button
              className={`button-cancel secondary-header text-capitalize w-75 my-3`}
              onClick={onClose}
            >
              {isProvider ? t("feedback.skipAndReview") : t("feedback.skipNow")}
            </Button>
          </div>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default FeedbackModal;
