import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: "en-Us",
  userData: null,
  token: null,
  loading: false,
  error: null,
  alertNote: "",
  alertType: "",
  selected: "",
};

export const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    userLogin: (state, action) => {
      state.token = action.payload.token;
      state.loading = false;
      state.error = action.payload.error;
    },
    userLoginFailure: (state, action) => {
      state.userData = null;
      state.error = action.payload;
    },
    logOut: (state, action) => {
      state.token = null;
      state.userData = null;
      state.error = null;
    },
    fetchUserData: (state, action) => {
      state.userData = action.payload || {};
    },
    refreshError: (state) => {
      state.error = null;
    },
    updateError: (state, action) => {
      state.error = action.payload;
    },
    setAlertNote: (state, action) => {
      state.alertNote = action.payload.text;
      state.alertType = action.payload.type;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
  },
});

export const {
  userLogin,
  userLoginFailure,
  logOut,
  fetchUserData,
  updateError,
  setAlertNote,
  refreshError,
  setSelected,
} = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
