import React, { useContext } from "react";
import MenuHeader from "../../components/Header/MenuHeader";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import moment from "moment";
import { GlobalContext } from "../../services/GlobalContext";
import { useTranslation } from "react-i18next";

const Help = () => {
  const { t } = useTranslation();
  const { provider } = useContext(GlobalContext);
  return (
    <div>
      <MenuHeader />
      <div
        className="p-4"
        style={{
          height: "90vh",
        }}
      >
        <Typography className="text-d bold-700 greeting-title">
          {`${t("homeAction.greeting2")} ${provider?.firstName || ""} ${
            provider?.lastName || ""
          } 👋`}
        </Typography>
        <Typography style={{ color: "#5D5D5C" }}>
          {moment().format("MMMM DD,YYYY")}
        </Typography>
        <Divider className="my-3" variant="fullWidth" component="p" />
        <div className="d-flex align-items-center gap-2">
          <Typography className="font-24 bold-700 text-primary">
            Welcome to FAQ's
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Help;
