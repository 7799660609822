import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import "./Company.css";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useGlobal from "../../hooks/useGlobal";
import { Form } from "react-bootstrap";

const Company = () => {
  const location = useLocation();
  const { userData } = useAuth();

  const { getOrg, updateOrg } = useGlobal();
  const [isEdit, setIsEdit] = useState(false);
  const [details, setDetails] = useState(null);

  useEffect(() => {
    const init = async () => {
      const org = await getOrg();
      setDetails(org);
    };
    init();
  }, []);

  if (!userData) {
    return <Navigate to={"/"} replace={location} />;
  }

  const onChangeText = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const onPrimaryAction = async () => {
    if (isEdit) {
      // save
      const { name, email, phone, address, website, logo } = details ?? {};
      const isUpdated = await updateOrg({
        name,
        email,
        phone,
        address,
        website,
        logo,
      });
      isUpdated && setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  };

  return (
    <div className="d-md-flex">
      <Grid className="p-4 w-100">
        <div className="d-flex justify-content-between align-items-center">
          <Grid>
            <Typography className="sessions-header bold-600">
              CareSpeak organization
            </Typography>
            <Typography className="sessions-heading-caption">
              Organization’s details
            </Typography>
          </Grid>
        </div>
        <Divider className="my-2" />

        <Grid className="mb-4">
          <div className="position-relative">
            <Grid container className="p-3 ">
              <Grid item sx={6} md={6} />
              <Grid item sx={6} md={6} container>
                <Grid item sx={12} md={6} className="px-2">
                  {isEdit && (
                    <Button
                      variant="contained"
                      color="error"
                      className=" w-100 p-2"
                      onClick={() => {
                        setIsEdit(!isEdit);
                      }}
                    >
                      <Typography className="text-capitalize">
                        Cancel
                      </Typography>
                    </Button>
                  )}
                </Grid>
                <Grid item sx={12} md={6} className="px-2">
                  <Button
                    variant="contained"
                    color="primary"
                    className="w-100 p-2"
                    onClick={onPrimaryAction}
                  >
                    <Typography className="text-capitalize">
                      {isEdit ? "Save" : "Edit"}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="settings-fileds">
              <Grid item xs={12} md={6} container className="p-3 px-4">
                <Grid item xs={12} md={4}>
                  <Typography className="acc-field-head">Name:</Typography>
                </Grid>
                <Grid item xs={12} md={8} container>
                  {isEdit ? (
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={details?.name}
                      onChange={onChangeText}
                    />
                  ) : (
                    <Typography className="acc-field">
                      {details?.name ?? "-"}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} container className="p-3 px-4">
                <Grid item xs={12} md={4}>
                  <Typography className="acc-field-head">Phone:</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  {!isEdit ? (
                    <Typography className="acc-field">
                      {details?.phone ?? "-"}
                    </Typography>
                  ) : (
                    <Form.Control
                      type="text"
                      name="phone"
                      placeholder="Enter phone number"
                      value={details?.phone}
                      onChange={onChangeText}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} container className="p-3 px-4">
                <Grid item xs={12} md={4}>
                  <Typography className="acc-field-head">Email:</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  {isEdit ? (
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={details?.email}
                      onChange={onChangeText}
                    />
                  ) : (
                    <Typography className="acc-field">
                      {details?.email ?? "-"}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} md={6} container className="p-3 px-4">
                <Grid item xs={12} md={4}>
                  <Typography className="acc-field-head">Address:</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  {isEdit ? (
                    <Form.Control
                      type="text"
                      name="address"
                      placeholder="Address"
                      value={details?.address}
                      onChange={onChangeText}
                    />
                  ) : (
                    <Typography className="acc-field">
                      {details?.address ?? "-"}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} container className="p-3 px-4">
                <Grid item xs={12} md={4}>
                  <Typography className="acc-field-head">Website:</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  {isEdit ? (
                    <Form.Control
                      type="text"
                      name="website"
                      placeholder="Website"
                      value={details?.website}
                      onChange={onChangeText}
                    />
                  ) : (
                    <Typography className="acc-field">
                      {details?.website ?? "-"}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} container className="p-3 px-4">
                <Grid item xs={12} md={4}>
                  <Typography className="acc-field-head">Logo:</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  {isEdit ? (
                    <Form.Control
                      type="text"
                      name="logo"
                      placeholder="Logo"
                      value={details?.logo}
                      onChange={onChangeText}
                    />
                  ) : (
                    <Typography className="acc-field">
                      {details?.logo ?? "-"}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Company;
