import React, { useState } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import "./Account.css";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Form } from "react-bootstrap";

const Account = () => {
  const location = useLocation();
  const { userData, updateuserApi } = useAuth();

  const [isEdit, setIsEdit] = useState(false);
  const [details, setDetails] = useState(userData);

  if (!userData) {
    return <Navigate to={"/"} replace={location} />;
  }

  const onChangeText = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const onPrimaryAction = async () => {
    if (isEdit) {
      // save
      const { firstName, lastName, phone, password } = details ?? {};
      const isUpdated = await updateuserApi({
        firstName,
        lastName,
        phone,
        password,
      });
      isUpdated && setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  };

  return (
    <div className="d-md-flex">
      <Grid className="p-4 w-100">
        <div className="d-flex justify-content-between align-items-center">
          <Grid>
            <Typography className="sessions-header bold-600">
              My Account
            </Typography>
            <Typography className="sessions-heading-caption">
              profile details
            </Typography>
          </Grid>
        </div>
        <Divider className="my-2" />

        <Grid className="mb-4">
          <div className="position-relative">
            <Grid container className="p-3 ">
              <Grid item sx={6} md={6} />
              <Grid item sx={6} md={6} container>
                <Grid item sx={12} md={6} className="px-2">
                  {isEdit && (
                    <Button
                      variant="contained"
                      color="error"
                      className=" w-100 p-2"
                      onClick={() => {
                        setIsEdit(!isEdit);
                      }}
                    >
                      <Typography className="text-capitalize">
                        Cancel
                      </Typography>
                    </Button>
                  )}
                </Grid>
                <Grid item sx={12} md={6} className="px-2">
                  <Button
                    variant="contained"
                    color="primary"
                    className="w-100 p-2"
                    onClick={onPrimaryAction}
                  >
                    <Typography className="text-capitalize">
                      {isEdit ? "Save changes" : "Edit settings"}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="py-3">
              <Grid item xs={12} md={6} className="pr-5">
                <Grid>
                  <Typography className="acc-field-head">
                    First Name:
                  </Typography>
                </Grid>
                <Grid className="my-2">
                  <Form.Control
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={details?.firstName}
                    onChange={onChangeText}
                    disabled={!isEdit}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className="pr-5">
                <Grid>
                  <Typography className="acc-field-head">Last Name:</Typography>
                </Grid>
                <Grid className="my-2">
                  <Form.Control
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={details?.lastName}
                    onChange={onChangeText}
                    disabled={!isEdit}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="py-2">
              <Grid item xs={12} md={6} className="pr-5">
                <Grid>
                  <Typography className="acc-field-head">Email:</Typography>
                </Grid>
                <Grid className="my-2">
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    value={details?.email}
                    onChange={onChangeText}
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className="pr-5">
                <Grid>
                  <Typography className="acc-field-head">Phone:</Typography>
                </Grid>
                <Grid className="my-2">
                  <Form.Control
                    type="number"
                    name="phone"
                    placeholder="Phone"
                    value={details?.phone}
                    onChange={onChangeText}
                    disabled={!isEdit}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="py-2">
              <Grid item xs={12} md={6} className="pr-5">
                <Grid>
                  <Typography className="acc-field-head">Password:</Typography>
                </Grid>
                <Grid className="my-2">
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="**********"
                    value={details?.password}
                    onChange={onChangeText}
                    disabled={!isEdit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Account;
