import { Icon } from "@virtuslab/tetrisly-icons";
import React from "react";
import Select, { components } from "react-select";

const SearchControl = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <Icon name="20-search" />
      {children}
    </components.Control>
  );
};

const FilterControl = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <Icon name="20-filter" />
      {children}
    </components.Control>
  );
};

const CustomSelect = (props) => {
  const styles = {
    control: (css) => ({
      ...css,
      paddingLeft: "1rem",
      width: props.width || 350,
    }),
  };

  return (
    <Select
      {...props}
      components={{ Control: props.isFilter ? FilterControl : SearchControl }}
      isSearchable
      styles={styles}
    />
  );
};

export default CustomSelect;
