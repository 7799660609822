import { useContext, useEffect, useRef, useState } from "react";
import { SocketContext } from "../services/SocketContext";

import { v4 as uuidv4 } from "uuid";
import { GlobalContext } from "../services/GlobalContext";
import { removeSilence } from "../utils/utils";

const useWhisper2 = ({ sessionId }) => {
  const micListener = useRef();
  const recorder = useRef();
  const stream = useRef();
  const speakingRef = useRef();

  const {
    user,
    setTranscripts,
    onTranslate,
    setProcessing,
    onStop,
    onSpeak,
    session,
    receiverConnected,
    isSpeaking,
    audio,
    stopSpeaking,
  } = useContext(SocketContext);

  const { languageSetting, isMuted, isTranslation } = useContext(GlobalContext);
  const [sampleRate, setSampleRate] = useState(44100);
  const [microphones, setMicrophones] = useState([]);
  const [defaultMicrophone, setDefaultMicrophone] = useState(null);

  const isCompleted = session?.sessionStatus === "COMPLETED";

  useEffect(() => {
    const getMicrophones = async () => {
      try {
        // Get media stream to access devices
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioInputDevices = devices.filter(
          (device) => device.kind === "audioinput"
        );
        setMicrophones(audioInputDevices);

        // Find the default microphone
        const defaultMic = audioInputDevices.find(
          (device) =>
            device.deviceId ===
            stream.getAudioTracks()[0].getSettings().deviceId
        );
        setDefaultMicrophone({
          label: defaultMic.label,
          value: defaultMic.deviceId,
        });
        setSampleRate(
          defaultMic?.label?.toLowerCase()?.includes("airpod") ? 24000 : 44100
        );

        // Stop the media stream to free up resources
        stream.getTracks().forEach((track) => track.stop());
      } catch (err) {
        console.error("Error accessing media devices.", err);
      }
    };

    getMicrophones();

    // Add event listener for device changes
    navigator.mediaDevices.addEventListener("devicechange", getMicrophones);

    // Cleanup event listener on unmount
    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        getMicrophones
      );
    };
  }, []);

  const onMicStartSpeaking = async () => {
    if (speakingRef.current && !isTranslation) {
      clearTimeout(speakingRef.current);
    }
    if (!isSpeaking) {
      console.log("start speaking");
      onSpeak();
      init();
    }
  };

  const stopAndSend = async () => {
    if (recorder.current) {
      const recordState = await recorder.current.getState();
      if (recordState === "recording" || recordState === "paused") {
        recorder.current && (await recorder.current.stopRecording());
        // recorder.current.destroy();
      }
      console.log(recordState, "recordState-stopped");
      onDataAvailable();
      await recorder.current.destroy();
      recorder.current = undefined;
    }
  };

  const onMicStopSpeaking = async () => {
    console.log("stop speaking");
    if (isTranslation) {
      console.log("User has been clicked stop button");
      onStop();
      stopAndSend();
    } else {
      speakingRef.current = setTimeout(async () => {
        console.log("User has been silent for 3 seconds");
        onStop();
        stopAndSend();
      }, 2000);
    }
  };

  const onWhispered = async (file) => {
    try {
      const apiKey = process.env.REACT_APP_API_TOKEN;
      // Whisper only accept multipart/form-data currently
      const body = new FormData();
      body.append("file", file);
      body.append("model", "whisper-1");
      body.append("language", user?.language || "en");
      const headers = {};
      headers["Content-Type"] = "multipart/form-data";
      if (apiKey) {
        headers["Authorization"] = `Bearer ${apiKey}`;
      }
      const { default: axios } = await import("axios");
      const response = await axios.post(
        "https://api.openai.com/v1/audio/transcriptions",
        body,
        {
          headers,
        }
      );
      return response.data.text;
    } catch (error) {
      console.log(error, "Something went wrong API got failed");
      // alert("Something went wrong! Speak again");
    }
  };

  const onStartStreaming = async () => {
    try {
      if (stream.current) {
        stream.current.getTracks().forEach((track) => track.stop());
      }
      stream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true,
        deviceId: defaultMicrophone?.deviceId,
      });
      if (!micListener.current && !isTranslation) {
        console.log("hark init");
        const { default: hark } = await import("hark");
        micListener.current = hark(stream.current, {
          interval: 100,
          play: false,
        });
        micListener.current.on("speaking", onMicStartSpeaking);
        micListener.current.on("stopped_speaking", onMicStopSpeaking);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onDataAvailable = async (audioBlob) => {
    try {
      let blob = audioBlob;
      const isRemoveSilence = false;
      if (recorder.current) {
        try {
          if (!blob) {
            blob = await recorder.current.getBlob();
          }
        } catch (error) {
          console.log(error, "error-blob");
          stopSpeaking(sessionId);
          return;
        }
        if (!blob || blob?.size <= 44) {
          console.log("No-blob");
          stopSpeaking(sessionId);
          return;
        }
        console.log("onDataAvailable", blob);
        const audioUrlPre = URL.createObjectURL(blob);
        console.log(audioUrlPre, "audioUrlPre");
        if (isRemoveSilence) {
          blob = await removeSilence(blob);
        }
        if (!blob || blob.size <= 358) {
          console.log("No-blob");
          stopSpeaking(sessionId);
          return;
        }
        const audioUrl = URL.createObjectURL(blob);
        console.log(audioUrl, "audioUrl");
        const text = await onWhispered(blob);
        if (!text) {
          console.log("No-text from blob");
          return;
        }
        const tempId = uuidv4();
        audio.current = blob;
        onTranslate(text, tempId, sessionId);
        setProcessing(false);
        setTranscripts((prev) => {
          return [
            ...prev,
            {
              transcriptText: text,
              tempId,
              createdAt: new Date().toISOString(),
              speakerId: user?.userId,
            },
          ];
        });
      }
    } catch (err) {
      stopSpeaking(sessionId);
      console.log(err);
    }
  };

  const init = async () => {
    try {
      console.log("init");

      if (!stream.current) {
        await onStartStreaming();
      }

      if (stream.current) {
        if (!recorder.current) {
          console.log("in init recordrtc");
          const {
            default: { RecordRTCPromisesHandler, StereoAudioRecorder },
          } = await import("recordrtc");
          const recorderConfig = {
            mimeType: "audio/wav",
            numberOfAudioChannels: 1, // mono
            recorderType: StereoAudioRecorder,
            // sampleRate: 44100, // Sample rate = 44.1khz
            // sampleRate: 16000, // Sample rate = 16khz
            // sampleRate: 48000, // 48 kHz
            sampleRate: sampleRate,
            // timeSlice: undefined,
            type: "audio",
            // ondataavailable: onDataAvailable,
          };
          recorder.current = new RecordRTCPromisesHandler(
            stream.current,
            recorderConfig
          );
        }
        const recordState = await recorder.current.getState();
        if (recordState === "inactive" || recordState === "stopped") {
          await recorder.current.startRecording();
        }
        if (recordState === "paused") {
          await recorder.current.resumeRecording();
        }
      }
    } catch (error) {
      console.log(error, "Permissions issue");
    }
  };

  useEffect(() => {
    console.log("let's init the process", isMuted);
    const check =
      receiverConnected &&
      !languageSetting &&
      !isCompleted &&
      !isMuted &&
      !isTranslation;
    if (check) {
      init();
    }
    return () => {
      if (micListener.current) {
        micListener.current.stop();
        // @ts-ignore
        micListener.current.off("speaking", onMicStartSpeaking);
        // @ts-ignore
        micListener.current.off("stopped_speaking", onMicStopSpeaking);
        micListener.current = undefined;
      }
      stopAndSend();
      if (stream.current) {
        stream.current.getTracks().forEach((track) => track.stop());
        stream.current = undefined;
      }
    };
  }, [
    receiverConnected,
    languageSetting,
    isCompleted,
    isMuted,
    defaultMicrophone,
    isTranslation,
  ]);

  return {
    microphones,
    defaultMicrophone,
    setDefaultMicrophone,
    onMicStopSpeaking,
    onMicStartSpeaking,
  };
};

export default useWhisper2;
