import React, { useContext, useEffect } from "react";
import {
  Card,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import "./Invoices.css";
import { Icon } from "@virtuslab/tetrisly-icons";
import Paper from "@mui/material/Paper";
import { GlobalContext } from "../../services/GlobalContext";
import moment from "moment";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useGlobal from "../../hooks/useGlobal";
import { saveAs } from "file-saver";
import { capitalizeFirstLetter } from "../../utils/utils";

const Filter = ({ filter, value, icon }) => {
  return (
    <Card className="d-flex p-1 gap-1 align-items-center">
      {icon && <Icon name={icon} className="filter-icon" />}
      <Typography className="filter-name">{filter}: </Typography>
      <Typography className="filter-name bold-600">{value} </Typography>
      <Icon name="20-close" className="filter-icon" />
    </Card>
  );
};

const Invoices = () => {
  const location = useLocation();
  const { userData } = useAuth();
  const filters = [
    {
      id: 1,
      filter: "Provider",
      value: "Value",
      icon: "20-user",
    },
    {
      id: 2,
      filter: "Patient",
      value: "Value",
      icon: "20-email",
    },
    {
      id: 3,
      filter: "Date",
      value: "This Month",
      icon: null,
    },
  ];

  const { invoices, setError } = useContext(GlobalContext);
  const { getInvoices } = useGlobal();

  useEffect(() => {
    getInvoices(10);
  }, []);

  const handleDownload = async (e, invoice) => {
    try {
      saveAs(invoice.invoice_pdf, `${invoice.number}.pdf`);
    } catch (error) {
      setError(error.message);
    }
  };

  const rows = invoices?.map((invoice) => {
    const {
      id,
      number,
      period_end,
      period_start,
      status,
      invoice_pdf,
      total,
      lines,
    } = invoice;
    const range = `${moment(period_start * 1000).format("MM/DD/YY")} - ${moment(
      period_end * 1000
    ).format("MM/DD/YY")}`;
    return {
      id: id,
      invoiceId: number,
      month: range,
      noOfCalls: lines?.data?.[0]?.quantity ?? 0,
      amount: (total / 100).toFixed(2),
      status: capitalizeFirstLetter(status),
      paymentMethod: "Card",
      more: (
        <Tooltip title="Download">
          <IconButton
            onClick={(e) => handleDownload(e, { invoice_pdf, number })}
          >
            <Icon name="20-download" />
          </IconButton>
        </Tooltip>
      ),
    };
  });

  if (!userData) {
    return <Navigate to={"/"} replace={location} />;
  }

  return (
    <div className="d-md-flex">
      <Grid className="p-4 w-100">
        <div className="d-flex justify-content-between align-items-center">
          <Grid>
            <Typography className="sessions-header bold-600">
              CareSpeak Invoices
            </Typography>
            <Typography className="sessions-heading-caption">
              Review your organization’s invoices
            </Typography>
          </Grid>
        </div>
        <Divider className="my-2" />
        <Grid
          container
          className="d-flex gap-2 align-items-center justify-content-between py-3 flex-wrap"
        >
          <Grid>
            {/* {filters?.map(({ filter, id, value, icon }) => {
            return (
              // <Grid item sm={6} md={3} lg={2} key={id}>
              <Filter key={id} filter={filter} value={value} icon={icon} />
              // </Grid>
            );
          })} */}
          </Grid>
          <Grid className="d-flex gap-1 align-items-center" role="button">
            <Icon name="20-filter" className="filter-icon primary-color" />
            <Typography className="font-14 primary-color">Filters</Typography>
          </Grid>
        </Grid>
        <Grid className="mb-4">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#F5F7F9" }}>
                <TableRow>
                  <TableCell>Invoice #</TableCell>
                  <TableCell>Billing Period</TableCell>
                  <TableCell># of calls</TableCell>
                  <TableCell>$ amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="right">Payment Method</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row) => (
                  <TableRow
                    key={row.sessionId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.invoiceId}
                    </TableCell>
                    <TableCell>{row.month}</TableCell>
                    <TableCell>{row.noOfCalls}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell align="right">{row.paymentMethod}</TableCell>
                    <TableCell align="right">{row.more}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default Invoices;
