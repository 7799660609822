import i18n from "i18next";
import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// import enJSON from "../translations/en.json";
import axios from "axios";
import { API_BASE_URL, BASE_NAME, BASE_URL_ } from "../constants/endpoints";

const sourceLanguage = localStorage.getItem("sourceLanguage");

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //   .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // resources: {
    //   en: { ...enJSON },
    //   pt: { ...enJSON },
    // }, // Where we're gonna put translations' files
    lng: sourceLanguage ?? "en", // Set the initial language of the App
    fallbackLng: "en", // use en if detected lng is not available
    debug: true,
    // saveMissing: true, // send not translated keys to endpoint
    interpolation: {
      escapeValue: false,
    },
    // react: {
    //   useSuspense: false,
    // },
    backend: {
      loadPath: `${API_BASE_URL}/public/translations/{{lng}}.json`,
      parse: (data) => {
        return data;
      },
      request: (options, url, payload, callback) => {
        axios
          .get(url)
          .then((res) => {
            console.log(
              res.data,
              `${BASE_URL_}/public/translations/{{lng}}.json`
            );
            callback(null, res);
          })
          .catch((err) => {
            console.log(err, `${BASE_URL_}/public/translations/{{lng}}.json`);
            callback(err, null);
          });
      },
    },
  });

export default i18n;
