import React, { useContext, useState } from "react";
import { Dialog, Grid, IconButton, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Icon } from "@virtuslab/tetrisly-icons";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import SearchComponent from "../Search/Search";
import ModalTranscript from "../Cards/ModalTranscript";
import "jspdf-autotable";
import jsPDF from "jspdf";
import { SocketContext } from "../../services/SocketContext";
import { GlobalContext } from "../../services/GlobalContext";
import { useNavigate } from "react-router-dom";
import InfoModal from "./InfoModal";
import useGlobal from "../../hooks/useGlobal";
import SOAPModal from "./SoapModal";
import FeedbackModal from "./FeedbackModal";

const ReviewModal = ({ open: isOpen, handleClose }) => {
  const navigate = useNavigate();
  const {
    transcripts,
    setTranscripts,
    user,
    setUser,
    setReceiver,
    setSession,
    setUsers,
    session,
  } = useContext(SocketContext);
  const { setIsLoading, encounter, setEncounter, setError } =
    useContext(GlobalContext);
  const isCompleted = session?.sessionStatus === "COMPLETED";
  const { generateSOAPNote, saveToEHR, getNote } = useGlobal();
  const [open, setOpen] = useState(isOpen);
  const { height } = useWindowDimensions();
  const [search, setSearch] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState(transcripts);
  const [soapNote, setSoapNote] = useState(session?.SOAPNote || "");
  const [soapModal, setSoapModal] = useState(false);
  const [feedbackAlert, setFeedbackAlert] = useState(
    !session?.publishedToEhr && !session?.SOAPNote && !isCompleted
  );

  // useEffect(() => {
  //   if (!encounter) {
  //     setIsLoading(true);
  //     api
  //       .get(
  //         `/athena/encounterNotes?practiceid=1959447&encounterid=${session?.ehrEncounterId}&departmentid=163&patientid=4`
  //       )
  //       .then((res) => {
  //         setEncounter(res.data.data);
  //         setIsLoading(false);
  //       })
  //       .catch(() => {
  //         setIsLoading(false);
  //       });
  //   }
  // }, []);

  const clearAndGoBack = () => {
    setUser(null);
    setReceiver(null);
    setSession(null);
    setUsers(null);
    navigate("/home");
  };

  const onClose = () => {
    setOpen(false);
    setIsSuccess(false);
    handleClose();
    clearAndGoBack();
  };

  const onSaveAsPDF = () => {
    const pdf = new jsPDF("p", "pt", "a4");
    const columns = ["Timestamp", "Name", "Transcript"];
    const rows = data.map((i) => {
      return [
        i.createdAt,
        `${i?.speaker?.firstName || ""} ${i?.speaker?.lastName || ""}`,
        i?.speakerId && i.speakerId !== user?.userId
          ? i?.translationText
          : i?.transcriptText,
      ];
    });

    pdf.text(235, 40, "Call Transcript");
    pdf.autoTable(columns, rows, {
      startY: 65,
      theme: "grid",
      styles: {
        font: "times",
        halign: "center",
        cellPadding: 3.5,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0],
      },
      headStyles: {
        textColor: [0, 0, 0],
        fontStyle: "normal",
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [166, 204, 247],
      },
      alternateRowStyles: {
        fillColor: [212, 212, 212],
        textColor: [0, 0, 0],
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
      },
      rowStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
      },
      tableLineColor: [0, 0, 0],
    });
    return pdf.output("datauristring");
  };

  const handleSendToEHR = async (e, soapNote) => {
    try {
      const note = getNote({ encounter, data });
      const payloadNote = !!soapNote ? soapNote?.replace(/\n/g, "<br>") : note;
      const payload = {
        discussionnotes: payloadNote,
        encounterid: session?.ehrEncounterId,
        sessionId: session.sessionId,
      };

      if (!note) {
        setError("Transcript is empty cannot publish!");
        return;
      }

      const isPublished = await saveToEHR(payload, !!soapNote);
      isPublished && setIsSuccess(true);
    } catch (error) {
      setError(error.message);
    }
  };

  const onEditClick = () => {
    setIsEdit(!isEdit);
    if (isEdit) {
      setTranscripts(data);
    }
  };

  const onGenerateSOAPNote = async () => {
    let note = "";
    data?.forEach((transcript, idx) => {
      const { firstName, lastName } = transcript?.speaker || {};
      note += `${firstName || ""} ${lastName || ""}: ${
        transcript?.speakerId && transcript.speakerId !== user?.userId
          ? transcript?.translationText
          : transcript?.transcriptText
      }\n`;
    });
    note +=
      "\ngenerate SOAP note using above data and don't include doctor name and include today's date if generated with date, no signature needed in SOAP note include ICD codes and CPT codes as seperate section";
    const messages = [{ role: "system", content: note }];
    const soap = await generateSOAPNote({
      messages,
      sessionId: session.sessionId,
    });
    if (soap) {
      setSoapNote(soap?.message?.content || "");
      setSoapModal(true);
    }
  };

  const onViewSOAPNote = (note) => {
    setSoapModal(true);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      fullWidth
      aria-labelledby="review modal"
      aria-describedby="review modal"
    >
      <Grid className="d-flex justify-content-between py-2 px-3 align-items-center primary-bg">
        <div className="d-flex align-items-center">
          <Icon name="20-voice" className="text-white" />
          <Typography className="text-white bold-700 mx-2">
            Encounter Note
          </Typography>
        </div>
        <IconButton onClick={onClose}>
          <CloseIcon className="text-white" />
        </IconButton>
      </Grid>

      <Grid
        className="px-2"
        style={{
          height: height - 200,
          overflow: "auto",
        }}
      >
        {!encounter ? null : (
          <div className="my-2">
            {encounter?.vitals && (
              <Grid id="vitals_details">
                <Typography className="mx-3 bold-600 font-16">
                  Vitals
                </Typography>
                <div>
                  <ul>
                    {Object.keys(encounter?.vitals)?.map((visit) => {
                      return (
                        <li
                          key={visit}
                        >{`${visit}: ${encounter?.vitals?.[visit]}`}</li>
                      );
                    })}
                  </ul>
                </div>
              </Grid>
            )}
            {encounter?.hpi && (
              <Grid className="my-3" id="hpi_details">
                <Typography className="mx-3 bold-600 font-16">HPI</Typography>
                <div>
                  <Typography className="mx-3 font-14">
                    {encounter?.hpi?.summarytext}
                  </Typography>
                </div>
              </Grid>
            )}
            {encounter?.ros && (
              <Grid className="mx-3 my-3" id="ros_details">
                <Typography className="bold-600 font-16">ROS</Typography>
                <div
                  dangerouslySetInnerHTML={{
                    __html: encounter?.ros?.summarytext,
                  }}
                  className="font-14 bold-400"
                >
                  {/* <Typography className="mx-3">
                    {encounter?.ros?.summarytext}
                  </Typography> */}
                </div>
              </Grid>
            )}
            {encounter?.assessment && (
              <Grid className="my-3" id="assessment_details">
                <Typography className="mx-3 bold-600 font-16">
                  Assessment
                </Typography>
                <div>
                  <Typography className="mx-3 font-14">
                    {encounter?.assessment?.assessmenttext}
                  </Typography>
                </div>
              </Grid>
            )}
            {encounter?.orders?.length && (
              <Grid className="my-3" id="prescription_details">
                <Typography className="mx-3 bold-600 font-16">
                  Prescription
                </Typography>
                <div>
                  <ul>
                    {encounter?.orders?.map((order) => {
                      return <li className="mx-3">{order?.description}</li>;
                    })}
                  </ul>
                </div>
              </Grid>
            )}
            {!!encounter?.patientgoals?.goals?.length && (
              <Grid className="my-3" id="goals_details">
                <Typography className="mx-3 bold-600 font-16">
                  Patient Goals
                </Typography>
                <div>
                  <Typography className="mx-3 font-14">
                    Pt is suffering from high fever and cough from past 3 days.
                    He has 101.5
                  </Typography>
                </div>
              </Grid>
            )}
            {encounter?.patientgoals && (
              <Grid className="my-3" id="patient_instructions_details">
                <Typography className="mx-3 bold-600 font-16">
                  Patient Instructions
                </Typography>
                <div>
                  <Typography className="mx-3 font-14">
                    {encounter?.patientgoals?.patientinstructions}
                  </Typography>
                </div>
              </Grid>
            )}
            {encounter?.followup && (
              <Grid className="my-3" id="followup_details">
                <Typography className="mx-3 bold-600 font-16">
                  Follow up
                </Typography>
                <div>
                  <Typography className="mx-3 font-14">
                    Pt has follow up appointment on{" "}
                    {encounter?.followup?.approximatedate}
                  </Typography>
                </div>
              </Grid>
            )}
          </div>
        )}
        <div className="d-flex justify-content-between align-items-center">
          <Typography className="mx-3 bold-700 font-18 my-2">
            Telehealth transcript
          </Typography>
        </div>
        <div>
          <div className="px-3 py-2 d-flex align-items-center gap-3 text-center">
            <SearchComponent
              placeholder=""
              value={search}
              onChange={(value) => setSearch(value)}
            />
          </div>
          {data
            ?.filter((f) => {
              if (!search) return true;
              return (
                f?.translationText
                  ?.toLowerCase()
                  ?.includes(search?.toLowerCase()) ||
                f?.transcriptText
                  ?.toLowerCase()
                  ?.includes(search?.toLowerCase()) ||
                `${f?.speaker?.firstName || ""} ${f?.speaker?.lastName || ""}`
                  ?.toLowerCase()
                  ?.includes(search?.toLowerCase())
              );
            })
            ?.map((d, index) => {
              return (
                <ModalTranscript
                  key={d.id}
                  {...d}
                  data={data}
                  idx={index}
                  setData={setData}
                  setIsEdit={setIsEdit}
                  isEdit={isEdit}
                  userId={user?.userId}
                />
              );
            })}
        </div>
      </Grid>
      <Grid container className="my-2">
        <Grid item sm={12} md={4} className="d-flex justify-content-center">
          <Button
            onClick={onEditClick}
            variant="contained"
            className="custom-btn mx-3"
          >
            <Typography className="bold-600 text-capitalize">
              {isEdit ? "Save Transcript" : "Edit transcript"}
            </Typography>
          </Button>
        </Grid>
        <Grid item sm={12} md={4} className="d-flex justify-content-center">
          <Button
            onClick={
              soapNote ?? session?.SOAPNote
                ? onViewSOAPNote
                : onGenerateSOAPNote
            }
            variant="contained"
            // color={"success"}
            className="custom-btn mx-3"
          >
            <Typography className="bold-600 text-capitalize">
              {soapNote ?? session?.SOAPNote
                ? "View SOAP note"
                : "Generate SOAP note"}
            </Typography>
          </Button>
        </Grid>
        {/* <Grid
          item
          sm={12}
          md={4}
          className="d-flex justify-content-center my-2 my-md-0"
        >
          <Button
            onClick={
              soapNote || session?.SOAPNote
                ? onViewSOAPNote
                : onGenerateSOAPNote
            }
            variant="contained"
            className="custom-btn mx-3"
          >
            <Typography className="bold-600 text-capitalize">
              {soapNote || session?.SOAPNote
                ? "View SOAP note"
                : "Generate SOAP note"}
            </Typography>
          </Button>
        </Grid>
        <Grid
          item
          sm={12}
          md={4}
          className="d-flex justify-content-center my-2 my-md-0"
        >
          <Button
            disabled={isEdit || session?.publishedToEhr}
            onClick={handleSendToEHR}
            variant="contained"
            className="custom-btn mx-3"
          >
            <Typography className="bold-600 text-capitalize">
              Publish to EHR
            </Typography>
          </Button>
        </Grid> */}
        {isSuccess && (
          <InfoModal
            open={isSuccess}
            handleClose={() => {
              onClose();
              clearAndGoBack();
            }}
            title="Success"
            subtext="Transcript has been successfully published to Athena"
            type="success"
          />
        )}
        {soapModal && (
          <SOAPModal
            soapNote={soapNote}
            setSoapNote={setSoapNote}
            open={soapModal}
            handleClose={() => {
              setSoapModal(false);
            }}
            handleSendToEHR={handleSendToEHR}
            publishedToEhr={session?.publishedToEhr}
          />
        )}
        {feedbackAlert && (
          <FeedbackModal
            open={feedbackAlert}
            handleClose={() => {
              setFeedbackAlert(false);
            }}
          />
        )}
      </Grid>
    </Dialog>
  );
};

export default ReviewModal;
