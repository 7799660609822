import React from "react";
import MenuHeader from "../../components/Header/MenuHeader";
import { Typography } from "@mui/material";

const AuthError = () => {
  return (
    <div>
      <MenuHeader />
      <div
        className="p-4"
        style={{
          height: "90vh",
        }}
      >
        <div className="d-flex align-items-center gap-2">
          <Typography className="font-24 bold-700 text-primary">
            Authentication Failed!
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default AuthError;
