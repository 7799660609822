import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import "./Sidebar.css";
import Logo from "../../assets/CareSpeak_Logo.png";
import { resourcesData, sidebarData } from "./options";
import { CssBaseline, Grid, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Profile from "../Header/Profile";
import useAuth from "../../hooks/useAuth";

const drawerWidth = 260;

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { selected, handleSelected } = useAuth();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const paths = {
    "/admin/home": "1-0",
    "/admin/company": "2-1",
    "/admin/users": "2-2",
    "/admin/invoices": "2-3",
    "/admin/billing": "2-4",
  };

  React.useEffect(() => {
    handleSelected(paths[location.pathname]);
  }, [location.pathname]);

  const drawer = (
    <Grid className="d-flex flex-column justify-content-between h-100">
      <Grid>
        {/* <Toolbar /> */}
        <img src={Logo} alt="logo" className="logo m-3" />
        <Divider />
        <List>
          {sidebarData.map(
            ({ id, title, Icon, isDisabled, subSections, path }, index) => {
              const isSel = selected?.split("-")?.[0] == id;
              const isSel1 = isSel && !!subSections?.length;
              return (
                <div key={id}>
                  <ListItem key={id} disablePadding>
                    <ListItemButton
                      disabled={isDisabled}
                      onClick={() => {
                        if (path) {
                          navigate(path);
                          handleSelected(`${id}-0`);
                        }
                      }}
                      sx={{
                        backgroundColor: isSel1
                          ? "#022F520F"
                          : isSel
                          ? "#E5EEFF"
                          : "#fff",
                        margin: "0 5px",
                        borderRadius: 2,
                      }}
                    >
                      <ListItemIcon>
                        {Icon && (
                          <Icon
                            style={{
                              color: isSel1
                                ? "#4A545E"
                                : isSel
                                ? "#1E50C0"
                                : "#272e35",
                            }}
                          />
                        )}
                      </ListItemIcon>
                      <div className="d-flex align-items-center justify-between w-100">
                        <Typography
                          className="section-title my-1"
                          sx={{
                            color: isSel1
                              ? "#4A545E"
                              : isSel
                              ? "#1E50C0"
                              : "#272e35",
                          }}
                        >
                          {title}
                        </Typography>
                        {!!subSections?.length && (
                          <Icon name="20-chevron-down" />
                        )}
                      </div>
                    </ListItemButton>
                  </ListItem>
                  {subSections?.map(
                    ({ id: id2, title, Icon, isDisabled, path }, index) => {
                      const isSelected =
                        selected?.split("-")?.[0] == id &&
                        selected?.split("-")?.[1] == id2;
                      return (
                        <ListItem key={id2} disablePadding>
                          <ListItemButton
                            disabled={isDisabled}
                            onClick={() => {
                              if (path) {
                                navigate(path);
                                handleSelected(`${id}-${id2}`);
                              }
                            }}
                            sx={{
                              backgroundColor: isSelected ? "#E5EEFF" : "#fff",
                              margin: "0 5px",
                              borderRadius: 2,
                            }}
                          >
                            <ListItemIcon>
                              {Icon && (
                                <Icon
                                  style={{
                                    color: isSelected ? "#1E50C0" : "#272e35",
                                  }}
                                />
                              )}
                            </ListItemIcon>
                            <Typography
                              className="section-title my-1 px-2"
                              sx={{
                                color: isSelected ? "#1E50C0" : "#272e35",
                              }}
                            >
                              {title}
                            </Typography>
                          </ListItemButton>
                        </ListItem>
                      );
                    }
                  )}
                </div>
              );
            }
          )}
        </List>
        <Divider />
        <List>
          <Typography className="section-heading font-12 bold-600 mx-4">
            RESOURCES
          </Typography>
          {resourcesData.map(({ id, title, Icon, link }, index) => (
            <ListItem key={id} disablePadding>
              <ListItemButton
                onClick={() => {
                  if (link) {
                    window.open(link, "_blank");
                  }
                }}
                sx={{
                  backgroundColor:
                    selected?.split("-")?.[0] == id ? "#E5EEFF" : "#fff",
                  margin: "0 5px",
                  borderRadius: 2,
                }}
              >
                <ListItemIcon>
                  <Icon
                    style={{
                      color:
                        selected?.split("-")?.[0] == id ? "#1E50C0" : "#272e35",
                    }}
                  />
                </ListItemIcon>
                <Typography
                  className="section-title my-1"
                  sx={{
                    color:
                      selected?.split("-")?.[0] == id ? "#1E50C0" : "#272e35",
                  }}
                >
                  {title}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid className="justify-content-end">
        <Divider />
        {/* <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              {userData?.firstName?.slice(0, 1) ?? "U"}
            </Avatar>
          }
          action={
            <IconButton
              aria-label="settings"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <Icon name="20-more-horizontal" />
            </IconButton>
          }
          title={`${userData?.firstName ?? ""} ${userData?.lastName ?? ""}`}
          subheader={userData?.email ?? ""}
        /> */}
        {/* <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleMyaccount}>My account</MenuItem>
          <MenuItem onClick={logoutUser}>Logout</MenuItem>
        </Menu> */}
      </Grid>
    </Grid>
  );

  return (
    <Box>
      <CssBaseline />
      <div className="mb-4 mb-lg-0 menu-div d-block d-lg-none w-100">
        <div
          className="d-flex d-md-none justify-between align-items-center mx-4"
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              display: { md: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Profile />
        </div>
      </div>

      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
