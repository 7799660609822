import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import "./signup.css";
import { Form } from "react-bootstrap";
import Logo from "../../assets/CareSpeak_Logo.png";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { GlobalContext } from "../../services/GlobalContext";
import useGlobal from "../../hooks/useGlobal";

const Signup = () => {
  const location = useLocation();
  const { inviteId } = useParams();
  const navigate = useNavigate();
  const { userData, error } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [data, setData] = useState(null);
  const { setIsLoading } = useContext(GlobalContext);
  const { inviteUserVerify, onboardUser } = useGlobal();

  useEffect(() => {
    const init = async () => {
      if (inviteId) {
        const user = await inviteUserVerify({ token: inviteId });
        setData(user);
        setEmail(user?.email);
      }
    };
    init();
  }, []);

  const onSignup = async () => {
    try {
      const variables = {
        email,
        password,
        userType: "ADMIN",
        firstName,
        lastName,
        orgId: data?.orgId,
      };
      setIsLoading(true);
      await onboardUser(variables);
      navigate("/");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  if (!inviteId) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (userData) {
    return <Navigate to="/admin/home" state={{ from: location }} replace />;
  }

  return (
    <Grid container className="vh-100">
      <Grid item xs={12} md={12} style={{ overflow: "hidden" }}>
        <div className={"d-flex flex-column justify-content-between vh-100"}>
          <Grid />
          <Grid className="d-flex justify-content-center">
            <Grid
              style={{ width: "350px" }}
              className="d-grid justify-content-center"
            >
              <div className="d-flex justify-content-center">
                <img src={Logo} alt="logo" className="logo-login" />
              </div>
              <p className="text-center mb-4 subhead2">
                {inviteId
                  ? `You've been invited to join ${
                      data?.orgName ?? ""
                    } on CareSpeak.`
                  : "Signup to the CareSpeak platform"}
              </p>
              <Grid className="mx-2 my-2">
                <Grid>
                  <p className="mb-2 labels">Email address</p>
                  <Form.Control
                    disabled
                    placeholder="john@example.com"
                    value={email}
                    onChange={(val) => setEmail(val.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid className="mx-2 my-2">
                <Grid>
                  <p className="mb-2 labels">First Name</p>
                  <Form.Control
                    placeholder="Enter First Name"
                    value={firstName}
                    onChange={(val) => setFirstName(val.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid className="mx-2 my-2">
                <Grid>
                  <p className="mb-2 labels">Last Name</p>
                  <Form.Control
                    placeholder="Enter Last Name"
                    value={lastName}
                    onChange={(val) => setLastName(val.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid className="mx-2 my-2">
                <Grid>
                  <p className="mb-2 labels">Password</p>
                  <Form.Control
                    placeholder="********"
                    type="password"
                    value={password}
                    onChange={(val) => setPassword(val.target.value)}
                  />
                </Grid>
                {/* <div className="d-flex justify-content-end">
                  <Link className="font-14 color-1 py-1" role="button">
                    Forgot Password?
                  </Link>
                </div> */}
                {error && (
                  <div>
                    <Typography
                      style={{ color: "red" }}
                      className="font-14 py-1"
                    >
                      {error}
                    </Typography>
                  </div>
                )}
              </Grid>
              <Button
                style={{
                  width: "350px",
                  backgroundColor: "#196742",
                  fontWeight: 700,
                }}
                onClick={onSignup}
                variant="contained"
                className={
                  "d-flex justify-content-center align-items-center p-2 text-capitalize"
                }
              >
                <Typography className="px-2">Sign up</Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid>
            <p className="text-center">
              Copyright © 2024 CareSMS, Inc. Confidential & Proprietary. All
              rights reserved.
            </p>
            <p className="text-center">
              <u> Terms of Service</u> | <u>Privacy Policy</u>
            </p>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default Signup;
