import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import "./InfoModal.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "../../screens/Billing/PaymentMethod/PaymentMethod";

const publishableKey = process.env.REACT_APP_STRIPE_PK;

const PaymentModal = ({ open: isOpen, handleClose, title, subtext, type }) => {
  const [open, setOpen] = useState(isOpen);

  const isSuccess = type === "success";
  const isError = type === "error";

  const onClose = () => {
    setOpen(false);
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <Grid
        className={`${
          isSuccess
            ? "success-header"
            : isError
            ? "error-header"
            : "info-header"
        }`}
      >
        <Grid className="d-flex justify-content-between align-items-center mx-3 my-2">
          <Grid className="d-flex align-items-center">
            <Typography className="cancel-title px-2 text-white">
              {isSuccess ? "Success" : isError ? "Error" : title}
            </Typography>
          </Grid>
          <IconButton onClick={onClose}>
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        <Elements stripe={loadStripe(publishableKey)}>
          <PaymentForm handleClose={handleClose} />
        </Elements>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentModal;
