import React, { useContext, useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from "@mui/material";
import { GlobalContext } from "../../../services/GlobalContext";
import useGlobal from "../../../hooks/useGlobal";

const PaymentForm = ({ handleClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const { setIsLoading } = useContext(GlobalContext);
  const { getPaymentMethods, createPaymentMethod } = useGlobal();

  useEffect(() => {
    setIsLoading(true);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (result.error) {
      setError("An error occurred. Please try again.");
      setIsLoading(false);
    } else {
      // Send payment method data to backend
      const { paymentMethod } = result;
      // Make API call to your backend to save the payment method

      try {
        const pm = await createPaymentMethod(paymentMethod);
        await getPaymentMethods(pm.custId);
        setIsLoading(false);
        handleClose();
      } catch (error) {
        setIsLoading(false);
        handleClose();
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="m-2 my-3">
        <CardElement onReady={() => setIsLoading(false)} />
      </div>
      <Button type="submit" disabled={!stripe}>
        Save Payment Method
      </Button>
      {error && <div>{error}</div>}
    </form>
  );
};

export default PaymentForm;
