import { Outlet } from "react-router-dom";
import Layout from "../screens/Layout/Layout";

const AdminLayout = () => {
  return (
    <div>
      <Layout>
        <Outlet />
      </Layout>
    </div>
  );
};

export default AdminLayout;
