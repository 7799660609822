import React, { createContext, useState, useRef, useEffect } from "react";
import socket from "../services/socket";
import { useTranslation } from "react-i18next";
import useGlobal from "../hooks/useGlobal";
import api from "./api";
import { getQueryString } from "../utils/utils";

const SocketContext = createContext();

const ContextProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [receiver, setReceiver] = useState(null);
  const [receivers, setReceivers] = useState([]);
  const [receiverSpeaking, setReceiverSpeaking] = useState(false);
  const [receiverConnected, setReceiverConnected] = useState(
    receiver?.isOnline
  );
  const [processing, setProcessing] = useState(false);
  const [transcripts, setTranscripts] = useState([]);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [token, setToken] = useState(null);
  const [endAlert, setEndAlert] = useState(false);
  const [type, setType] = useState(null);
  // const [audio, setAudio] = useState(null);
  const [snackPack, setSnackPack] = React.useState([]);
  const audio = useRef(null);

  const uploadRec = async ({ audio, transcriptId, sessionId, isNoise }) => {
    try {
      const formData = new FormData();
      formData.append("file", audio);
      const res = await api.patch(
        `/transcript/rec/${transcriptId}?${getQueryString({
          sessionId,
          hasNoise: isNoise,
        })}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      return null;
    }
  };

  // const name = localStorage.getItem("name");
  // const type = localStorage.getItem("type");

  const handleType = (data) => {
    setType(data);
    localStorage.setItem("type", data);
  };

  useEffect(() => {
    setType(localStorage.getItem("type"));
  }, []);

  const sourceLanguage = localStorage.getItem("sourceLanguage");

  const setSourceLanguage = (val) =>
    localStorage.setItem("sourceLanguage", val);

  const splitUsers = (data) => {
    const usersData = data || users;
    const userData = usersData?.find((u) => u?.userId === user?.userId);

    if (userData) {
      const isPatient = type === "Patient";
      if (isPatient) {
        i18n.changeLanguage(userData.language);
      }
      setUser((prev) => ({
        ...prev,
        // language: userData.language,
        ...userData,
      }));
      if (!userData?.isOnline) {
        socket.emit("set:details", {
          // language: sourceLanguage,
          sessionId: session?.sessionId,
          userId: user?.userId,
        });
      }
    }
    usersData?.map((outer) => {
      const rec = receivers?.find(
        (inner) => outer?.isOnline && inner?.userId === outer?.userId
      );
      if (rec) {
        setReceiver((prev) => ({
          ...rec,
          // language: outer.language,
          ...outer,
        }));
      }

      return rec;
    });
  };

  useEffect(() => {
    users?.length > 0 && user?.userId && splitUsers();
  }, [users]);

  useEffect(() => {
    setReceiverConnected(receiver?.isOnline);
  }, [receiver?.isOnline]);

  const getDetailsHandler = (data) => {
    setUsers(data);
    // splitUsers(data);
  };

  useEffect(() => {
    console.log("connection useEffect");
    const socketHandler = (id) => {
      console.log("socket connected: ", id, session?.sessionId);
      if (session?.sessionId) {
        socket.emit("fetch:details", {
          sessionId: session?.sessionId,
        });
        const isCompleted = session?.sessionStatus === "COMPLETED";
        const isProvider = type === "Provider";
        onFetchUser(user, session?.sessionId, users, isCompleted, isProvider);
      }
    };
    socket.on("socket:id", socketHandler);

    const handleReconnect = () => {
      console.log("Reconnected to the server");
      if (session?.sessionId) {
        socket.emit("fetch:details", {
          sessionId: session?.sessionId,
        });
        const isCompleted = session?.sessionStatus === "COMPLETED";
        const isProvider = type === "Provider";
        onFetchUser(user, session?.sessionId, users, isCompleted, isProvider);
      }
    };

    // Handle reconnection
    socket.on("reconnect", handleReconnect);
    return () => {
      socket.off("socket:id", socketHandler);
      socket.off("reconnect", handleReconnect);
    };
  }, [session, type, user, users]);

  useEffect(() => {
    const setDetailsHandler = ({ users }) => {
      setUsers(users);
    };

    socket.on("set:details", setDetailsHandler);

    const permissionHandler = (msg) => {
      console.log("permission:notgranted", msg);
    };

    socket.on("permission:notgranted", permissionHandler);

    const limitHandler = (msg) => {
      console.log("connection:limitReached", msg);
    };

    socket.on("connection:limitReached", limitHandler);

    const dataHandler = (msg) => {
      console.log("data:invalid", msg);
    };

    socket.on("data:invalid", dataHandler);

    const startHandler = (data) => {
      setReceiverSpeaking(true);
    };
    socket.on("start:conversation", startHandler);

    const stopHandler = (data) => {
      setReceiverSpeaking(false);
    };
    socket.on("stop:conversation", stopHandler);

    const userJoinHandler = ({ message, name }) => {
      setReceiverConnected(true);
      setSnackPack((prev) => [
        ...prev,
        {
          message: `${name} ${t(message)}`,
          key: new Date().getTime(),
        },
      ]);
    };
    socket.on("user:join", userJoinHandler);

    const userLeftHandler = ({ message, users, name }) => {
      setSnackPack((prev) => [
        ...prev,
        {
          message: `${name} ${t(message)}`,
          key: new Date().getTime(),
        },
      ]);
      setReceiverConnected(false);
      setReceiverSpeaking(false);
      getDetailsHandler(users);
    };
    socket.on("user:left", userLeftHandler);

    const updateHandler = (transcript) => {
      console.log("update translation going on...");
      setTranscripts((prev) =>
        prev.map((f) => {
          if (f.transcriptId === transcript.transcriptId) {
            return transcript;
          }
          return f;
        })
      );
    };
    socket.on("update-transcription", updateHandler);

    const onFinishAlert = () => {
      setSession((prev) => ({ ...prev, sessionStatus: "COMPLETED" }));
      setEndAlert(true);
    };

    socket.on("finish:call", onFinishAlert);

    return () => {
      socket.off("set:details", setDetailsHandler);
      socket.off("permission:notgranted", permissionHandler);
      socket.off("connection:limitReached", limitHandler);
      socket.off("data:invalid", dataHandler);
      socket.off("start:conversation", startHandler);
      socket.off("stop:conversation", stopHandler);
      socket.off("update-transcription", updateHandler);
      socket.off("user:join", userJoinHandler);
      socket.off("user:left", userLeftHandler);
    };
  }, []);

  useEffect(() => {
    const translateHandler = async (transcript) => {
      try {
        console.log("translation going on...");
        setTranscripts((prev) => {
          const tempPrev = prev.filter((f) =>
            transcript.tempId ? f.tempId !== transcript.tempId : true
          );
          // console.log(tempPrev, "tempPrev", transcript, prev);
          return [...tempPrev, transcript];
        });
        // console.log(
        //   audio.current,
        //   "audio",
        //   transcript?.speakerId,
        //   user?.userId
        // );
        if (audio.current && transcript?.speakerId === user?.userId) {
          console.log("in audio upload...", audio.current);
          await uploadRec({
            transcriptId: transcript.transcriptId,
            audio: audio.current,
            isNoise: "false",
            sessionId: transcript?.sessionId,
          });
          audio.current = null;
        }
      } catch (error) {
        audio.current = null;
      }
    };
    socket.on("translate", translateHandler);

    return () => {
      socket.off("translate", translateHandler);
    };
  }, [user]);

  useEffect(() => {
    socket.on("get:details", getDetailsHandler);
    return () => {
      socket.off("get:details", getDetailsHandler);
    };
  }, []);

  // useEffect(() => {
  //   if (session?.sessionId) {
  //     socket.emit("fetch:details", {
  //       sessionId: session?.sessionId,
  //     });
  //   }
  // }, [session]);

  const onLangSelect = (lang) => {
    // setSourceLanguage(lang);
    socket.emit("set:details", {
      language: lang,
      sessionId: session?.sessionId,
      userId: receiver?.userId,
      isLanguageUpdate: true,
    });
  };

  const onSpeak = () => {
    setIsSpeaking(true);
    socket.emit("start:conversation", {
      receiverId: receiver?.userId,
      sessionId: session?.sessionId,
    });
  };

  const onStop = () => {
    setIsSpeaking(false);
    console.log("stop recording");
    socket.emit("stop:conversation", {
      receiverId: receiver?.userId,
      sessionId: session?.sessionId,
    });
  };

  const stopSpeaking = (sessionId) => {
    socket.emit("stop:conversation", {
      receiverId: receiver?.userId,
      sessionId: sessionId || session?.sessionId,
    });
  };

  const onTranslate = (text, tempId, sessionId) => {
    console.log("stop translation", receiver, user);
    stopSpeaking(sessionId);
    socket.emit("translate", {
      text,
      receiverId: receiver?.userId,
      sessionId: sessionId || session.sessionId,
      userId: user?.userId,
      tempId,
    });
  };

  const onUpdateTranscription = (text, transcriptId) => {
    console.log("update translation starts");
    socket.emit("update-transcription", {
      text,
      receiverId: receiver?.userId,
      sessionId: session.sessionId,
      userId: user?.userId,
      transcriptId,
    });
  };

  const onFinishCall = () => {
    socket.emit("finish:call", {
      receiverId: receiver?.userId,
      userType: type,
      sessionId: session.sessionId,
    });
  };

  const onFetchUser = (user, sessionId, users, isCompleted, isPatient) => {
    socket.emit("join:call", {
      userId: user.userId,
      sessionId: sessionId,
      isCompleted,
      isPatient,
      users,
    });
  };

  return (
    <SocketContext.Provider
      value={{
        type,
        receiver,
        setReceiver,
        user,
        setUser,
        users,
        setUsers,
        onLangSelect,
        onTranslate,
        receiverSpeaking,
        setReceiverSpeaking,
        transcripts,
        setTranscripts,
        onSpeak,
        isSpeaking,
        setIsSpeaking,
        onFinishCall,
        setSession,
        session,
        onFetchUser,
        sourceLanguage,
        setSourceLanguage,
        setToken,
        token,
        processing,
        setProcessing,
        endAlert,
        setEndAlert,
        onUpdateTranscription,
        onStop,
        audio,
        snackPack,
        setSnackPack,
        receivers,
        setReceivers,
        receiverConnected,
        setReceiverConnected,
        handleType,
        stopSpeaking,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { ContextProvider, SocketContext };
