import React, { useState, useEffect } from "react";
import { Button, CardContent, Typography } from "@mui/material";
import { Icon } from "@virtuslab/tetrisly-icons";
import CheckIcon from "../../../assets/check.png";
import useGlobal from "../../../hooks/useGlobal";
import InfoModal from "../../../components/Modals/InfoModal";

const ProductDisplay = ({ subscription, isValidSubscription }) => {
  const { createCheckout } = useGlobal();
  const onCheckout = async () => {
    await createCheckout();
  };
  return (
    <CardContent>
      {isValidSubscription && (
        <img alt="check" src={CheckIcon} className="check-icon" />
      )}
      <div className="wallet-circle">
        <Icon name="20-wallet" />
      </div>
      <div className="px-1 py-3">
        <Typography className="billing-next-date">
          {isValidSubscription ? "Current plan" : "Basic plan"}
        </Typography>
        <Typography className="payment-methods">$1.99 per call</Typography>
      </div>
      {isValidSubscription ? (
        <div>
          <Typography className="plan-desc">$0 monthly fees</Typography>
          <Typography className="plan-desc">
            Billing monthly on the 1st, based upon call volume
          </Typography>
        </div>
      ) : subscription ? (
        <div>
          <Typography className="plan-desc">
            Your subscription is expired, please subscribe to a plan
          </Typography>
        </div>
      ) : (
        <div>
          <Typography className="plan-desc">
            You are not subscribed to any plan, please subscribe to a plan
          </Typography>
        </div>
      )}
      {!isValidSubscription && (
        <Button
          variant="contained"
          color={"primary"}
          className="add-new-btn px-3 mt-2"
          onClick={onCheckout}
        >
          <Typography className="text-capitalize font-14">Subscribe</Typography>
        </Button>
      )}
    </CardContent>
  );
};

export default function Plans({ subscription, isValidSubscription }) {
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");
  const { updateSubId } = useGlobal();

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      const sessId = query.get("session_id");
      updateSubId(sessId);
      setSuccess(true);
      setSessionId(sessId);
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage("Subscription canceled -- subscribe when you're ready.");
    }
  }, [sessionId]);

  const onClose = () => {
    setSuccess(false);
    setMessage("");
  };

  return (
    <div>
      <ProductDisplay
        subscription={subscription}
        isValidSubscription={isValidSubscription}
      />
      {success && sessionId !== "" && (
        <InfoModal
          open={success}
          handleClose={onClose}
          title="Success"
          subtext="Subscription to CareSpeak plan successful!"
          type="success"
        />
      )}
      {message !== "" && (
        <InfoModal
          open={message}
          handleClose={onClose}
          title="Subscription canceled!"
          subtext={message}
          type="error"
        />
      )}
    </div>
  );
}
