import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { ThemeProvider } from "@mui/styles";
import "./App.css";
import MainRoutes from "./routes";
import { ContextProvider } from "./services/SocketContext";
import { ErrorBoundary } from "react-error-boundary";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { GlobalProvider } from "./services/GlobalContext";
import { BASE_NAME } from "./constants/endpoints";
import AudioRecorder from "./screens/AudioRecorder";
import theme from "./theme";

function App() {
  return (
    <Provider store={store}>
      <GlobalProvider>
        <ContextProvider>
          <ThemeProvider theme={theme}>
            <ErrorBoundary
              fallback={
                <h1 className="d-flex align-items-center justify-content-center vh-100">
                  Something went wrong !
                </h1>
              }
            >
              <BrowserRouter>
                <MainRoutes />
                {/* <AudioRecorder /> */}
              </BrowserRouter>
            </ErrorBoundary>
          </ThemeProvider>
        </ContextProvider>
      </GlobalProvider>
    </Provider>
  );
}

export default App;
