import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import "./Billing.css";
import { Icon } from "@virtuslab/tetrisly-icons";
import Paper from "@mui/material/Paper";
import PaymentModal from "../../components/Modals/PaymentModal";
import Plans from "./Plans/Plans";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import moment from "moment";
import { GlobalContext } from "../../services/GlobalContext";
import useGlobal from "../../hooks/useGlobal";
import { capitalizeFirstLetter } from "../../utils/utils";

const Status = ({ text }) => {
  return (
    <div className="d-flex justify-content-center">
      <div className="method-status px-2">
        <div className="d-flex align-items-center gap-2">
          <span className="dot" />
          <Typography className="font-14">{text}</Typography>
        </div>
      </div>
    </div>
  );
};

const Options = ({ pm, setDefaultPaymentMethod, getPaymentMethods }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    !pm.default && setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetDefault = async () => {
    try {
      await setDefaultPaymentMethod(pm);
      await getPaymentMethods();
      handleClose();
    } catch (error) {
      handleClose();
    }
  };

  return (
    <>
      <IconButton
        aria-label="options"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Icon name="20-more-horizontal" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleSetDefault}>Set as Default</MenuItem>
      </Menu>
    </>
  );
};

const Billing = () => {
  const location = useLocation();
  const { setIsLoading, paymentMethods, subscription } =
    useContext(GlobalContext);
  const { getUserApi, userData, error } = useAuth();
  const [isPaymentAdd, setIsPaymentAdd] = useState(false);
  const {
    getPaymentMethods,
    getSubscriptions,
    getProduct,
    setDefaultPaymentMethod,
    validateSubscription,
  } = useGlobal();

  const isValidSubscription = validateSubscription(subscription);

  const rows = paymentMethods?.map((pm) => {
    const isCard = pm.type === "card";
    return {
      id: 2,
      name: isCard
        ? capitalizeFirstLetter(
            `${pm?.card?.brand} ending in ${pm?.card?.last4}`
          )
        : pm?.link?.email,
      type: capitalizeFirstLetter(pm.type),
      expiration: isCard ? `${pm?.card?.exp_month}/${pm?.card?.exp_year}` : "-",
      status: pm.default ? <Status text={"Default"} /> : "-",
      more: (
        <Options
          pm={pm}
          setDefaultPaymentMethod={setDefaultPaymentMethod}
          getPaymentMethods={getPaymentMethods}
        />
      ),
    };
  });
  useEffect(() => {
    const init = async () => {
      try {
        setIsLoading(true);
        await getUserApi();
        if (userData?.billing?.custId) {
          getPaymentMethods();
        }

        if (userData?.billing?.subId) {
          const sub = await getSubscriptions();

          getProduct(sub);
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    init();
  }, []);

  if (!userData) {
    return <Navigate to={"/"} replace={location} />;
  }

  return (
    <div className="d-md-flex">
      <Grid className="p-4 w-100 ">
        <Typography className="billing-header bold-600">
          Billing Details
        </Typography>
        <Typography className="billing-heading-caption">
          Review your organization’s billing plan and payment methods
        </Typography>
        <Divider className="my-2" />
        {isValidSubscription && (
          <Grid className="py-2">
            <Typography className="billing-next-date">
              Next billing date
            </Typography>
            <Typography className="billing-date">
              {moment(new Date(subscription?.current_period_end * 1000)).format(
                "MMMM DD, YYYY"
              )}
            </Typography>
          </Grid>
        )}
        {!isValidSubscription && subscription && (
          <Grid className="py-2">
            <Typography className="billing-next-date">
              Due billing date
            </Typography>
            <Typography className="billing-date">
              {moment(new Date(subscription.current_period_end * 1000)).format(
                "MMMM DD, YYYY"
              )}
            </Typography>
          </Grid>
        )}
        <Divider className="my-2" />
        <Grid className="d-flex justify-content-between align-items-center py-3">
          <Typography className="payment-methods">Payment methods</Typography>
          <Button
            variant="contained"
            color={"primary"}
            className="add-new-btn "
            onClick={() => setIsPaymentAdd(true)}
          >
            <Icon name="20-plus" />
            &nbsp;
            <Typography className="text-capitalize font-14">Add new</Typography>
          </Button>
          {isPaymentAdd && (
            <PaymentModal
              title={"Add Payment Method"}
              open={isPaymentAdd}
              handleClose={() => setIsPaymentAdd(false)}
            />
          )}
        </Grid>
        <Grid className="mb-4">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#F5F7F9" }}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Payment Type</TableCell>
                  <TableCell align="center">Expiration</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row.type}
                    </TableCell>
                    <TableCell align="center">{row.expiration}</TableCell>
                    <TableCell align="center">{row.status}</TableCell>
                    <TableCell align="right">{row.more}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Divider className="my-1" />
        <Grid className="d-flex justify-content-between align-items-center py-3">
          <Typography className="payment-methods">Plan details</Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6} className="p-3">
            <Card className="plan-card">
              <Plans
                subscription={subscription}
                isValidSubscription={isValidSubscription}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={6} className="p-3">
            <Card>
              <CardContent>
                <div className="wallet-circle">
                  <Icon name="20-wallet" />
                </div>
                <div className="px-1 py-3">
                  <Typography className="billing-next-date">
                    Interested in another plan?
                  </Typography>
                  <Typography className="payment-methods">
                    Contact us
                  </Typography>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color={"primary"}
                    className="add-new-btn px-3"
                  >
                    <Typography className="text-capitalize font-14">
                      Get in touch
                    </Typography>
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Billing;
