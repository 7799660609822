import {
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "./HomeActions.css";
import Button from "@mui/material/Button";
import { Icon } from "@virtuslab/tetrisly-icons";
import CustomSelect from "../../../components/Select/CustomSelect";
import { languages } from "../../../data";
import { SocketContext } from "../../../services/SocketContext";
import { v4 as uuidv4 } from "uuid";
import Menu from "@mui/material/Menu";
import axios from "axios";
import { BASE_URL, BASE_URL_ } from "../../../constants/endpoints";
import copy from "copy-to-clipboard";
import { GlobalContext } from "../../../services/GlobalContext";
import moment from "moment";
import InfoModal from "../../../components/Modals/InfoModal";
import SwitchText from "../../../components/SwitchText/SwitchText";
import { useTranslation } from "react-i18next";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import PreviousEncounterModal from "../../../components/Modals/PreviousEncounterModal";
import useGlobal from "../../../hooks/useGlobal";

const HomeActions = ({
  startRecording,
  stopRecording,
  setEndCall,
  isValidSubscription,
  languagePopup,
  isTranslation,
}) => {
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [endSessionAlert, setEndSessionAlert] = useState(false);
  const [showPrevEnc, setShowPrevEnc] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = () => {
    handleClose();
    copy(`${BASE_URL_}${window.location.pathname}`);
  };

  const {
    receiver,
    user,
    receiverSpeaking,
    onTranslate,
    onSpeak,
    setTranscripts,
    isSpeaking,
    setIsSpeaking,
    users,
    session,
    setProcessing,
    onFinishCall,
    setSession,
    onStop,
    transcripts,
    receiverConnected,
  } = useContext(SocketContext);
  const {
    inputType,
    setHasMicAccess,
    hasMicAccess,
    provider,
    setError,
    setInputType,
    isMuted,
    setIsMuted,
  } = useContext(GlobalContext);
  const { parseToken } = useGlobal();

  const name = localStorage.getItem("name");
  const type = localStorage.getItem("type");

  const onMute = () => setIsMuted(!isMuted);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setHasMicAccess(true);
      })
      .catch((err) => {
        alert(t("permission.noMicPermissions"));
        setHasMicAccess(false);
      });
  }, []);

  const onStartStop = async () => {
    try {
      if (isSpeaking) {
        setIsSpeaking(false);
        await stopRecording();
        // setProcessing(true);
      } else {
        if (!receiver?.language) {
          alert(t("homeAction.targetLanguageMissing"));
        }
        setIsSpeaking(true);
        // onSpeak();
        await startRecording();
      }
    } catch (error) {
      console.log(error, "err");
      onStop();
      setProcessing(false);
    }
  };

  const onSendText = () => {
    const tempId = uuidv4();
    setTranscripts((prev) => [
      ...prev,
      {
        text,
        tempId,
        createdAt: new Date().toISOString(),
        name: name,
      },
    ]);
    onTranslate(text, tempId, session?.sessionId);
    setText("");
  };

  const onChangeText = (e) => {
    setText(e.target.value);
  };

  const onEndCallAction = () => {
    setEndSessionAlert(true);
  };

  const handlePreviousEncounter = () => {
    setShowPrevEnc(true);
  };

  const onEndCall = async () => {
    try {
      setEndSessionAlert(false);
      setEndCall(true);
      if (!isCompleted) {
        onFinishCall();
        setSession((prev) => ({ ...prev, sessionStatus: "COMPLETED" }));
        const createdAt = moment(session?.createdAt);
        const updatedAt = moment();

        // Calculate the difference in hours and minutes
        const duration = moment.duration(updatedAt.diff(createdAt));

        // Extract hours and minutes from the duration
        const hoursDifference = Math.floor(duration.asHours());
        const minutesDifference = duration.minutes();

        console.log(
          hoursDifference + " hours " + minutesDifference + " minutes",
          provider?.billing?.subId
        ); // Output the difference in hours and minutes

        const data = {
          sessionId: session?.sessionId,
          sessionStatus: "COMPLETED",
          subId: provider?.billing?.subId,
          duration: `${
            String(hoursDifference).length === 1
              ? `0${hoursDifference}`
              : hoursDifference
          }:${
            String(minutesDifference).length === 1
              ? `0${minutesDifference}`
              : minutesDifference
          }:00`,
        };
        // setIsLoading(true);
        await axios.patch(`${BASE_URL}/session/${session?.sessionId}`, data);
      }

      // setIsLoading(false);
      // navigate("/home");
    } catch (error) {
      setError(error.message);
      console.log(error.message);
    }
  };

  const handleChange = (e) => {
    setInputType(e.target.checked ? "text" : "audio");
  };

  // if (!name || !type) {
  //   navigate("/");
  // }

  const btnIcon = {
    speaking: "20-check-circle",
    disabled: "20-placeholder",
    stopped: "20-voice",
  };

  const isCompleted = session?.sessionStatus === "COMPLETED";

  const isDisabled =
    !hasMicAccess ||
    isCompleted ||
    // !receiver?.firstName ||
    receiverSpeaking ||
    !receiver?.language ||
    !user?.language ||
    (!isSpeaking && !receiverConnected);
  // ||
  // (type === "Provider" && !isValidSubscription);

  useEffect(() => {
    isDisabled &&
      console.log(
        `has mic access: ${hasMicAccess},
    session completed: ${isCompleted},
    Is receiver speaking: ${receiverSpeaking},
    has receiver connected: ${!!receiverConnected},
    additional: ${!(!isSpeaking && !receiverConnected)},
    Is Disabled: ${isDisabled},
    `,
        "user",
        user,
        "receiver",
        receiver,
        "users",
        users,
        "isValidSubscription",
        isValidSubscription
      );
  }, [
    isDisabled,
    hasMicAccess,
    receiver,
    user,
    receiverConnected,
    isValidSubscription,
  ]);

  const btnStatus = isDisabled
    ? "disabled"
    : isSpeaking
    ? "speaking"
    : "stopped";

  const isPatient = receiver?.userType === "PATIENT" || type === "PATIENT";

  const receiverName = receiver?.firstName || "";

  return (
    <div>
      <div className="d-md-flex justify-content-between">
        <Typography className="text-d bold-700 greeting-title mx-4">
          {t("homeAction.greeting2")} {user?.firstName || ""} 👋
        </Typography>
        <div>
          {type === "Provider" && isValidSubscription && !languagePopup && (
            <Grid
              container
              className="my-2 my-md-0 px-4 px-md-0 gap-2 gap-md-0"
            >
              {!isCompleted && (
                <Grid item xs={12} sm={4}>
                  <Tooltip title={t("homeAction.generatePatientLink")}>
                    <Button
                      onClick={handleClick}
                      color="success"
                      variant="contained"
                      className="end-btn generate-link mx-md-3"
                    >
                      <Icon name="20-link" />
                    </Button>
                  </Tooltip>
                </Grid>
              )}

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <div className="d-flex align-items-center justify-content-between w-100 px-3">
                  <Typography>
                    {`${BASE_URL_}${window.location.pathname}`}
                  </Typography>
                  <span className="mx-3" />
                  <Tooltip title="Copy link">
                    <IconButton onClick={handleCopy}>
                      <Icon name="20-clipboard" />
                    </IconButton>
                  </Tooltip>
                </div>
              </Menu>
              {!isCompleted && (
                <Grid item xs={12} sm={4}>
                  <Tooltip title={t("homeAction.previousEncounter")}>
                    <Button
                      onClick={handlePreviousEncounter}
                      variant="contained"
                      color={"inherit"}
                      className="end-btn prev-enc mx-md-3"
                    >
                      <Icon name="20-file" />
                    </Button>
                  </Tooltip>
                </Grid>
              )}
              <Grid item xs={12} sm={!isCompleted ? 4 : 12}>
                <Tooltip
                  title={
                    isCompleted
                      ? t("homeAction.viewSession")
                      : t("homeAction.endSession")
                  }
                >
                  <Button
                    // disabled={isDisabled}
                    onClick={isCompleted ? onEndCall : onEndCallAction}
                    variant="contained"
                    color={isCompleted ? "primary" : "error"}
                    className="end-btn  mx-md-3 my-2 my-sm-0"
                  >
                    {isCompleted ? (
                      <Icon name="20-book" />
                    ) : (
                      <Icon name="20-phone-missed" />
                    )}
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
      {/* {!isCompleted && (
        <Grid className="d-md-flex m-4 align-items-center">
          <Grid className="d-flex align-items-center">
            <Grid>
              <Icon name="20-phone-forwarded" className="transcript-icon" />
            </Grid>
            <Typography className="text-d bold-600 language-select-text mx-2">
              {t("homeAction.preferredLanguage")}:
            </Typography>
          </Grid>
          <Grid className="my-2 my-md-0">
            <CustomSelect
              options={languages}
              value={lang}
              onChange={(val) => {
                // console.log(val, "val");
                onLangSelect(val.value);
                setLang(val);
                i18n.changeLanguage(val.value);
              }}
            />
          </Grid>
        </Grid>
      )} */}
      <Grid className="m-4 py-3">
        {isCompleted ? (
          <Typography className="connected-text">
            {t("homeAction.connectedWith")}{" "}
            <span className="bold-700 primary-color">{receiverName}</span>{" "}
            {t("homeAction.onThisSession")}
          </Typography>
        ) : receiver?.firstName && receiverConnected ? (
          <Typography className="connected-text">
            {t("homeAction.nowConnectedWith")}{" "}
            <span className="bold-700 primary-color">{receiverName}</span>{" "}
            {t("homeAction.onThisSession")}
          </Typography>
        ) : (
          <Typography className="connected-text">
            {!!transcripts?.length
              ? isPatient
                ? t("homeAction.reconnectWithPatient")
                : t("homeAction.reconnectWithProvider")
              : isPatient
              ? t("homeAction.waitingForPatient")
              : t("homeAction.waitingForProvider")}{" "}
          </Typography>
        )}
        {!isCompleted && receiver?.firstName && receiverConnected && (
          <Grid className="d-flex my-2 align-items-center">
            <Grid>
              <Icon name="20-phone-callback" className="translation-icon" />
            </Grid>
            <Typography className="language-select-text bold-600 mx-2">
              {receiverName} {t("homeAction.language")}:
            </Typography>
            <Typography className="language-select-text">
              <u>
                {languages?.find((l) => l.value === receiver?.language)?.label}
              </u>
            </Typography>
          </Grid>
        )}
        {!isCompleted && (
          <div>
            {inputType === "text" ? (
              <Grid container className="d-flex mt-4 align-items-center">
                <Grid item xs={12} md={9}>
                  <TextField
                    id="filled-textarea"
                    label={t("homeAction.enterText")}
                    multiline
                    className="w-100"
                    // variant="filled"
                    value={text}
                    onChange={onChangeText}
                    InputLabelProps={{
                      style: {
                        fontSize: 20,
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  className="d-flex align-items-center justify-content-center my-2 my-md-0"
                >
                  <Button
                    disabled={isDisabled}
                    onClick={onSendText}
                    variant="contained"
                    color={"primary"}
                    className="custom-btn w-75"
                  >
                    <Typography className="bold-600 text-capitalize">
                      {t("homeAction.send")}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid className="d-flex justify-content-center justify-content-md-start">
                {isTranslation && (
                  <Button
                    disabled={!isSpeaking && isDisabled}
                    onClick={onStartStop}
                    variant="contained"
                    color={isSpeaking ? "error" : "primary"}
                    startIcon={<Icon name={btnIcon[btnStatus]} />}
                    className="custom-btn my-2"
                  >
                    <Typography className="bold-600 text-capitalize">
                      {isSpeaking
                        ? t("homeAction.stopSpeaking")
                        : t("homeAction.beginSpeaking")}
                    </Typography>
                  </Button>
                )}
              </Grid>
            )}
            {receiverSpeaking && (
              <Typography className="bold-600 text-danger mx-2">
                {t("homeAction.userSpeaking")}
              </Typography>
            )}
            <div className="d-flex justify-content-between">
              <div>
                {/* {receiver?.language !== "en" && ( */}
                <SwitchText inputType={inputType} handleChange={handleChange} />
                {/* )} */}
              </div>
              {!isTranslation && (
                <Tooltip
                  title={
                    <span className={isMuted ? "mute-text" : ""}>
                      {t("homeAction.mute")}
                    </span>
                  }
                >
                  <Button
                    variant="contained"
                    className="mute-unmute"
                    onClick={onMute}
                  >
                    {isMuted ? <MicOffOutlinedIcon /> : <MicNoneOutlinedIcon />}
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        )}
      </Grid>
      {endSessionAlert && (
        <InfoModal
          open={endSessionAlert}
          handleClose={() => {
            setEndSessionAlert(false);
          }}
          title={t("homeAction.endSession")}
          subtext={t("homeAction.endSessionSubtitle")}
          // type="success"
          isActionable={true}
          submitText={t("homeAction.stopRecording")}
          onSubmit={onEndCall}
        />
      )}
      {showPrevEnc && (
        <PreviousEncounterModal
          open={showPrevEnc}
          handleClose={() => {
            setShowPrevEnc(false);
          }}
          note={session?.previousEncounterNote}
        />
      )}
    </div>
  );
};

export default HomeActions;
