import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { Icon } from "@virtuslab/tetrisly-icons";
import React, { useContext } from "react";
import { SocketContext } from "../../../services/SocketContext";
import useAudio from "../../../hooks/useAudio";
import api from "../../../services/api";
import { GlobalContext } from "../../../services/GlobalContext";
import { useTranslation } from "react-i18next";
// import TranslationCard from "../../../components/Cards/TranslationCard";

const HomeResponse = ({ isCompleted }) => {
  const { t } = useTranslation();
  const { receiver, transcripts, user } = useContext(SocketContext);
  const { setIsLoading, setError } = useContext(GlobalContext);
  const [playing, toggle, setUrl] = useAudio("");

  const onPlay = async () => {
    try {
      const getLatest = [...transcripts]
        ?.reverse()
        ?.find(
          (trans) => trans?.speakerId && trans?.speakerId !== user?.userId
        );
      // console.log(getLatest, "getlatest", transcripts);
      if (!getLatest) {
        return alert("No latest translation found!");
      }
      setIsLoading(true);
      const res = await api.get(`/transcript/tts/${getLatest?.transcriptId}`, {
        text: getLatest?.translationText,
      });
      setIsLoading(false);
      if (res.data.data) {
        setUrl(res.data.data);
        toggle();
      }
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      console.log(error, "err");
    }
  };

  return (
    <div>
      <Grid className="d-flex justify-content-end">
        {!!transcripts?.length && !isCompleted && (
          <Tooltip title={t("homeAction.tts")}>
            <Button variant="contained" className="tts-icon" onClick={onPlay}>
              <Icon name="20-volume-max" />
            </Button>
          </Tooltip>
        )}
      </Grid>
    </div>
  );
};

export default HomeResponse;
