const menuOptions = [
  {
    id: 1,
    name: "header.home",
    path: "/home",
    icon: "20-view-dashboard",
    noIcon: true,
    hasSubsections: false,
    access: ["*"],
  },
  {
    id: 2,
    name: "header.help",
    path: "/help",
    icon: "20-users",
    noIcon: true,
    hasSubsections: false,
    access: ["*"],
    // disabled: true,
  },
];
export default menuOptions;
